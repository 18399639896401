import "./HeroBg.css";
import {useNavigate} from "react-router-dom";

import React from "react";

export const HeroBg = () => {
    const Navigate = useNavigate();
    return (
        <div className="parallax">
            <div className="hero-text">
                <h2 className="bg_text">
                    Essential clothes for <br></br> sighthound dogs
                </h2>
                <button onClick={() => Navigate("product-listing")} className="shop-now-btn">
                    View Collection
                </button>
            </div>
        </div>
    );
};
