import "./ProductModal.css";
import React from "react";
import {useState} from "react";
import {toast} from "react-hot-toast";
import {useProducts} from "../../../../../../contexts/ProductsProvider";
import {useAuth} from "../../../../../../contexts/AuthProvider";
import {
    createProductService,
    updateProductService
} from "../../../../../../services/admin-services/adminProductsService";
import {ChromePicker} from "react-color";
import {Tooltip} from 'react-tooltip'
import {deleteImageService, uploadImageService} from "../../../../../../services/admin-services/adminUploadService";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState, ContentState, convertToRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {RxTrash} from "react-icons/rx";
import {CgShoppingCart} from "react-icons/cg";


export const ProductModal = () => {
    const [error, setError] = useState("");
    const {auth} = useAuth();
    const {
        productsDataState,
        setIsProductModalOpen,
        productForm,
        setProductForm,
        sizeForm,
        setSizerForm,
        isEdit,
        setIsEdit,
        getAdminProducts
    } = useProducts();

    // Description Editor
    const blocksFromHtml = htmlToDraft(productForm.description);
    const {contentBlocks, entityMap} = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const [editorDivState, setEditorState] = useState(EditorState.createWithContent(contentState))

    const onEditorStateChange = (editorState2) => {
        setProductForm({...productForm, description: draftToHtml(convertToRaw(editorDivState.getCurrentContent()))})
        setEditorState(editorState2)
    }

    // Materials Editor
    const blocksMaterialsFromHtml = htmlToDraft(productForm?.materials || "");
    const {contentBlocks: contentMaterialsBlocks, entityMap: entityMaterialsMap} = blocksMaterialsFromHtml;
    const contentMaterialsState = ContentState.createFromBlockArray(contentMaterialsBlocks, entityMaterialsMap);
    const [editorMaterialsDivState, setMaterialsEditorState] = useState(EditorState.createWithContent(contentMaterialsState))

    const onMaterialsEditorStateChange = (editorMaterialsState) => {
        setProductForm({
            ...productForm,
            materials: draftToHtml(convertToRaw(editorMaterialsDivState.getCurrentContent()))
        })
        setMaterialsEditorState(editorMaterialsState)
    }

    // Care Editor
    const blocksCareFromHtml = htmlToDraft(productForm.care || "");
    const {contentBlocks: contentCareBlocks, entityMap: entityCareMap} = blocksCareFromHtml;
    const contentCareState = ContentState.createFromBlockArray(contentCareBlocks, entityCareMap);
    const [editorCareDivState, setCareEditorState] = useState(EditorState.createWithContent(contentCareState))

    const onCareEditorStateChange = (editorCareState) => {
        setProductForm({...productForm, care: draftToHtml(convertToRaw(editorCareDivState.getCurrentContent()))})
        setCareEditorState(editorCareState)
    }

    const updateProduct = async (product) => {
        try {
            setError("");
            await updateProductService(auth.token, auth.token_type, product);
            setProductForm({
                name: "",
                category_name: null,
                category_id: null,
                is_stock: false,
                description: "",
                trending: false,
                sizes: [],
                background_color: null,
                img: null,
                img_file: null,
                photos: [],
                materials: "",
                care: "",
            });
            setSizerForm({
                size: 1,
                original_price: 0,
                discounted_price: 0,
            })
            setIsProductModalOpen(false);
            setIsEdit(false);
            getAdminProducts()
            toast.success(`product updated successfully!`);
        } catch (error) {
            let error_msg = ""
            if (Array.isArray(error.response?.data?.detail)) {
                error.response?.data?.detail.forEach((err) => {
                        toast.error(err.msg);
                        error_msg += err.msg + " ";
                    }
                );
                console.error("updateProduct error", error_msg);
                setError(error_msg);
                toast.error(error_msg);
            } else {
                console.error("updateProduct error NOT Array", error.response);
                toast.error(error.response?.data?.detail?.error);
                setError(error.response?.data?.detail?.error);
            }
        }
    };

    const addProduct = async (product) => {
        try {
            setError("");
            await createProductService(auth.token, auth.token_type, product);
            setProductForm({
                name: "",
                category_name: null,
                category_id: null,
                is_stock: false,
                description: "",
                trending: false,
                sizes: [],
                background_color: null,
                img: null,
                img_file: null,
                photos: [],
                materials: "",
                care: "",
            });

            setSizerForm({
                size: 1,
                original_price: 0,
                discounted_price: 0,
            })
            setIsProductModalOpen(false);
            await getAdminProducts()
            toast.success("New product added successfully!");
        } catch (error) {

            let error_msg = ""
            if (Array.isArray(error.response?.data?.detail)) {
                error.response?.data?.detail.forEach((err) => {
                        toast.error(err.msg);
                        error_msg += err.msg + " ";
                    }
                );
                console.error("addProduct error", error_msg);
                setError(error_msg);
                toast.error(error_msg);
            } else {
                console.error("addProduct error NOT Array", error.response);
                toast.error(error.response?.data?.detail?.error);
                setError(error.response?.data?.detail?.error);
            }
        }
    };

    const handleCategoryChange = (e) => {
        setProductForm({
            ...productForm,
            category_id: e.target.value,
            category_name: e.target.label,
        });
    }

    const handleChangeColor = (color) => {
        setProductForm({...productForm, background_color: color.hex});
    };

    const handleChangeImg = async (e) => {
        if (productForm.img?.includes("blob")) {
            try {
                const response = await deleteImageService(auth.token, auth.token_type, productForm.img_file);
                if (response.status === 200) {
                    console.log("deleteImageService deleted successfully ", response.data)
                } else {
                    console.error("deleteImageService : ", response.data)
                    setError(response.data);
                }
            } catch (error) {
                console.error(error);
                setError(error);
                toast.error(`delete temporary file failed! ${error}`);
            }
        }

        try {
            const response = await uploadImageService(auth.token, auth.token_type, e.target.files[0]);
            if (response.status === 200) {
                setProductForm({
                    ...productForm,
                    img: URL.createObjectURL(e.target.files[0]),
                    img_file: response.data.file_id
                });
            } else {
                console.error("downloadTemporaryPhoto : ", response.data)
                setError(response.data);
            }
        } catch (error) {
            console.error(error);
            setError(error);
            toast.error(`upload temporary file failed! ${error}`);
        }
    }

    const handleChangeMultyImages = async (e) => {
        try {
            const response = await uploadImageService(auth.token, auth.token_type, e.target.files[0]);
            if (response.status === 200) {

                let addedFiles = productForm.photos.concat({
                    id: response.data.file_id,
                    img_url: URL.createObjectURL(e.target.files[0])
                })

                setProductForm({...productForm, photos: addedFiles});

            } else {
                console.error("uploadImageService Multy : ", response.data)
                setError(response.data);
            }
        } catch (error) {
            console.error(error);
            setError(error);
            toast.error(`upload multy temporary file failed! ${error}`);
        }
    }

    const handleMultyImagesDelete = async (id, image_url) => {
        if (image_url?.includes("blob")) {
            try {
                const response = await deleteImageService(auth.token, auth.token_type, id);
                if (response.status === 200) {
                    console.log("handleMultyImagesDelete deleted successfully ", response.data)
                } else {
                    console.error("handleMultyImagesDelete : ", response.data)
                    setError(response.data);
                }
            } catch (error) {
                console.error(error);
                setError(error);
                toast.error(`delete multy temporary file failed! ${error}`);
            }
        }
        let updatedProductPhotos = productForm.photos.filter((photo) => photo.id !== id);
        setProductForm({...productForm, photos: updatedProductPhotos});
    }

    const handleCancelButton = async (e) => {
        e.preventDefault();
        if (productForm.img?.includes("blob")) {
            try {
                const response = await deleteImageService(auth.token, auth.token_type, productForm.img_file);
                if (response.status === 200) {
                    console.log("Cancel deleteImageService deleted successfully ", response.data)


                } else {
                    console.error("deleteImageService : ", response.data)
                    setError(response.data);
                }
            } catch (error) {
                console.error(error);
                setError(error);
                toast.error(`delete temporary file failed! ${error}`);
            }
        }

        setIsEdit(false);
        setProductForm({
            name: "",
            category_name: null,
            category_id: null,
            is_stock: false,
            description: "",
            trending: false,
            sizes: [],
            background_color: null,
            img: null,
            img_file: null,
            photos: [],
            materials: "",
            care: "",
        });

        setSizerForm({
            size: 1,
            original_price: 0,
            discounted_price: 0,
        })
        setIsProductModalOpen(false)
    }

    const handleSizeUpdateButton = (e) => {
        e.preventDefault();
        const updatedSizes = [...productForm.sizes];
        const existingSizeIndex = updatedSizes.findIndex((size) => size.size === sizeForm.size);

        if (existingSizeIndex !== -1) {
            updatedSizes[existingSizeIndex] = sizeForm;
        } else {
            updatedSizes.push(sizeForm);
        }
        setProductForm({...productForm, sizes: updatedSizes});
    }

    const handleSizeDeleteButton = (size) => {
        const updatedSizes = productForm.sizes.filter((item) => item.size !== size);
        setProductForm({...productForm, sizes: updatedSizes});

    }

    const ColorDiv = <div className="example-container">
        <p data-tooltip-id="my-tooltip-orange">
            {productForm.background_color}
        </p>
        <Tooltip
            id="my-tooltip-orange"
            clickable={true}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <ChromePicker
                    id="bkg-color"
                    className="product-color-picker"
                    name="background_color"
                    color={productForm.background_color || "aqua"}
                    onChangeComplete={handleChangeColor}
                />
            </div>
        </Tooltip>
    </div>

    const imgDiv = <div style={{display: "inline-flex"}}>
        <input type="file" style={{display: "none"}} id="product-img" onChange={handleChangeImg}/>
        <label htmlFor="product-img">
            <img
                alt={productForm.name}
                style={{maxWidth: "50px"}}
                src={productForm.img ? productForm.img?.includes("blob") ? productForm.img : `/api/uploaded/${productForm.img}` : null}
            />
            <span className="upload-button">
                Upload
            </span>
        </label>
    </div>

    const imagesDiv = <div style={{width: "100%", textAlign: "center", marginTop: "15px"}}>
        <div className="multy-image-area">
            {productForm.photos.map(({id, img_url}) => (
                <div className="image-area">
                    <img
                        alt={id}
                        className="upload-more-img"
                        src={img_url ? img_url?.includes("blob") ? img_url : `/api/uploaded/${img_url}` : null}
                    />
                    <a
                        href="#"
                        className="remove-image"
                        onClick={() => handleMultyImagesDelete(id, img_url)}
                        style={{display: "inline"}}>
                        &#215;
                    </a>
                </div>
            ))}
        </div>
        <input type="file" style={{display: "none"}} id="product-imgs" onChange={handleChangeMultyImages}/>
        <label htmlFor="product-imgs">
            <span className="upload-more-button">Upload One More Photo</span>
        </label>
    </div>

    const priceDiv = <div className="sizes-container">
        <div className="size-input-row">
            <div className="input-row">
                <label className="input-title" htmlFor="product_size">Size</label>
                <input
                    id="product_size"
                    name="size"
                    value={sizeForm.size}
                    onChange={(e) =>
                        setSizerForm({...sizeForm, size: e.target.value})
                    }
                    type="number"
                />
            </div>
            <div className="input-row">
                <label className="input-title" htmlFor="price">Price</label>
                <input
                    name="price"
                    id="price"
                    required
                    value={sizeForm.original_price}
                    onChange={(e) =>
                        setSizerForm({...sizeForm, original_price: e.target.value})
                    }
                    type="number"
                    placeholder="Enter Price"
                />
            </div>
            <div className="input-row">
                <label className="input-title" htmlFor="discount">Discount price</label>
                <input
                    id="discount"
                    name="discount"
                    value={sizeForm.discounted_price}
                    onChange={(e) =>
                        setSizerForm({...sizeForm, discounted_price: e.target.value})
                    }
                    type="number"
                    placeholder="Enter Discount Price"
                />
            </div>
            <button className="add-size-btn" onClick={(e) => {
                handleSizeUpdateButton(e)
            }}>Update
            </button>
        </div>
        <div className="sizes-table">
            <div className="size-row" style={{fontWeight: "600"}}>
                <div className="size-cell">Size</div>
                <div className="size-cell">Price</div>
                <div className="size-cell">Discount</div>
                <div className="size-cell"></div>
            </div>
            {productForm.sizes.map(({size, original_price, discounted_price}) => (
                <div className="size-row">
                    <div className="size-cell">{size}</div>
                    <div className="size-cell">{original_price}</div>
                    <div className="size-cell">{discounted_price}</div>
                    <div className="size-cell">
                        <div onClick={() => handleSizeDeleteButton(size)}>
                            <RxTrash color={"rgb(183,61,61)"} size={25}/>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>

    const titleDiv = <div className="title-container">
        <div style={{width: "50%"}}>
            <div className="input-row">
                <label className="input-title" htmlFor="fname">Title</label>
                <input
                    name="title"
                    id="fname"
                    required
                    value={productForm.name}
                    onChange={(e) =>
                        setProductForm({...productForm, name: e.target.value})
                    }
                    placeholder="Enter Product Title"
                />
            </div>

            <div className="input-row">
                <label className="input-title" htmlFor="product_category">Category</label>
                <select
                    id="product_category"
                    value={productForm.category_id}
                    onChange={handleCategoryChange}>
                    <option value={null}>---</option>
                    {productsDataState.categories.map((option) => (
                        <option value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>
        </div>

        <div style={{width: "50%"}}>

            <div className="input-row">
                <label className="input-title" htmlFor="bkg-color">Background color :</label>
                <div style={{width: "55%", display: "inline-flex"}}>
                    <div className="color-picker-container"
                         style={{backgroundColor: productForm.background_color}}></div>
                    {ColorDiv}
                </div>
            </div>
            <div className="input-row">
                <label className="input-title" htmlFor="is_stock">Is in stock</label>
                <input
                    id="is_stock"
                    name="is_stock"
                    defaultChecked={Boolean(productForm.is_stock)}
                    onChange={(e) =>
                        setProductForm({...productForm, is_stock: !productForm.is_stock})
                    }
                    type="checkbox"
                />
            </div>
            <div className="input-row">
                <label className="input-title" htmlFor="trending">Is trending</label>
                <input
                    id="trending"
                    name="trending"
                    defaultChecked={Boolean(productForm.trending)}
                    onChange={(e) =>
                        setProductForm({...productForm, trending: !productForm.trending})
                    }
                    type="checkbox"
                />
            </div>
        </div>
    </div>


    return (
        <div className="product-modal-container">
            <div className="product-input-container">
                <h1>{(isEdit) ? "Product Form" : "New Product form"}</h1>
                <form
                    onSubmit={(e) => {

                        if (!isEdit) {
                            e.preventDefault();
                            addProduct(productForm)
                        } else {
                            e.preventDefault();
                            updateProduct(productForm)
                        }
                    }}
                    className="input-containter"
                >
                    <div className="input-row">
                        <label className="input-title" htmlFor="img_path">Main IMG</label>
                        {imgDiv}
                    </div>
                    <div className="input-row">
                        {imagesDiv}
                    </div>
                    {titleDiv}
                    {priceDiv}
                    <div className="input-row-description">
                        <label className="input-title" htmlFor="description">Description</label>
                        <div>
                            <Editor
                                editorState={editorDivState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="text-editor"
                                onEditorStateChange={onEditorStateChange}
                            />
                        </div>
                    </div>

                    <div className="input-row-description">
                        <label className="input-title" htmlFor="materials">Materials</label>
                        <div>
                            <Editor
                                editorState={editorMaterialsDivState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="text-editor"
                                onEditorStateChange={onMaterialsEditorStateChange}
                            />
                        </div>
                    </div>
                    <div className="input-row-description">
                        <label className="input-title" htmlFor="care">Care</label>
                        <div>
                            <Editor
                                editorState={editorCareDivState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="text-editor"
                                onEditorStateChange={onCareEditorStateChange}
                            />
                        </div>
                    </div>
                    {error && <span className="error">{error}</span>}
                    <div className="btn-container">
                        <button className="cancel" onClick={handleCancelButton}>Cancel</button>
                        <input className="submit" type="submit" value="Save"/>
                    </div>
                </form>
            </div>
        </div>
    );
};
