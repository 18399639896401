import {TbAdjustmentsHorizontal} from "react-icons/tb";
import {RxCross2} from "react-icons/rx";
import {useState} from "react";
import {Link} from "react-router-dom";

import React from "react";
import "./AdminMenu.css";
import {useAuth} from "../../../../contexts/AuthProvider";

export const AdminMenu = () => {
    const [isMenuOn, setIsMenuOn] = useState(false);
    const {currentPage, setCurrentPage} = useAuth();

    return (
        <div>
            <div
                className={
                    isMenuOn
                        ? "menu-container menu-container-mobile-open"
                        : "menu-container menu-container-mobile-closed"
                }
            >
                <div
                    className={
                        !isMenuOn
                            ? "menu-header menu-header-mobile-closed"
                            : "menu-header menu-header-mobile-open"
                    }
                >
                    <span className="close-tab" onClick={() => setIsMenuOn(!isMenuOn)}>
                        {!isMenuOn ? <TbAdjustmentsHorizontal/> : <RxCross2/>}
                    </span>
                    <h2>MENU</h2>
                </div>

                <div
                    className={isMenuOn ? "menu-types-container menu-types-container-mobile" : "menu-types-container"}>
                    <Link
                        role='button'
                        className={currentPage === "admin" ? "reset-btn-active" : "reset-btn"}
                        onClick={() => setCurrentPage("admin")}
                        to="/admin"
                    >
                        PRODUCTS
                    </Link>
                    <Link
                        className={currentPage === "admin_categories" ? "reset-btn-active" : "reset-btn"}
                        onClick={() => setCurrentPage("admin_categories")}
                        to="/admin/categories"
                    >
                        CATEGORIES
                    </Link>
                    <Link
                        className={currentPage === "admin_orders" ? "reset-btn-active" : "reset-btn"}
                        onClick={() => setCurrentPage("admin_orders")}
                        to="/admin/orders"
                    >
                        ORDERS
                    </Link>
                    <Link
                        className={currentPage === "admin_clients" ? "reset-btn-active" : "reset-btn"}
                        onClick={() => setCurrentPage("admin_clients")}
                        to="/admin/clients"
                    >
                        CLIENTS
                    </Link>
                </div>
            </div>
        </div>
    );
};
