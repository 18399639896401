import "./CountryModal.css";
import React from "react";
import ReactSelect from "react-select";


export const CountryModal = ({countryName, handleCountryChange, setIsCountryOpen, countries, updateCountry}) => {

    return (
        <div className="country-modal-container">
            <div className="country-input-container">
                <h3>Choose your Country of delivery</h3>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        updateCountry()
                    }}
                    className="input-containter"
                >
                    <ReactSelect
                        id="country-select"
                        value={countryName}
                        onChange={handleCountryChange}
                        options={countries.map((country) => ({value: country.code, label: country.name}))}
                        isSearchable={true}
                    />

                    <div className="country-btn-container">
                        <button className="cancel" onClick={() => setIsCountryOpen(false)}>Cancel</button>
                        <input className="submit" type="submit" value="Save"/>
                    </div>
                </form>
            </div>
        </div>
    );
};