// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.products-page-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-top: 20px;
}

@media (min-width: 768px) {
    .products-page-container {
        margin-top: 100px;
        padding: 30px 30px 30px 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: space-evenly;
    }

    .product-details-description {
        margin-top: 15px;
    }

    .product-tax {
        margin-bottom: 20px;
        margin-top: -20px;
    }

    .product-tax > * {
        color: var(--primary-color);
        font-weight: 600;
    }
}

@media (max-width: 768px) {
    .product-details-description {
        margin-top: 15px;
    }

    .product-tax {
        margin-bottom: 20px;
        margin-top: -20px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/ProductDetails/ProductDetails.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,wBAAwB;IACxB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI;QACI,iBAAiB;QACjB,yBAAyB;QACzB,aAAa;QACb,qBAAqB;QACrB,uBAAuB;QACvB,6BAA6B;IACjC;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,mBAAmB;QACnB,iBAAiB;IACrB;;IAEA;QACI,2BAA2B;QAC3B,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;;IAEA;QACI,mBAAmB;QACnB,iBAAiB;IACrB;AACJ","sourcesContent":[".products-page-container {\n    display: flex;\n    flex-flow: column nowrap;\n    align-items: center;\n    margin-top: 20px;\n}\n\n@media (min-width: 768px) {\n    .products-page-container {\n        margin-top: 100px;\n        padding: 30px 30px 30px 0;\n        display: flex;\n        flex-flow: row nowrap;\n        align-items: flex-start;\n        justify-content: space-evenly;\n    }\n\n    .product-details-description {\n        margin-top: 15px;\n    }\n\n    .product-tax {\n        margin-bottom: 20px;\n        margin-top: -20px;\n    }\n\n    .product-tax > * {\n        color: var(--primary-color);\n        font-weight: 600;\n    }\n}\n\n@media (max-width: 768px) {\n    .product-details-description {\n        margin-top: 15px;\n    }\n\n    .product-tax {\n        margin-bottom: 20px;\n        margin-top: -20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
