import "./ProductDetails.css";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useData} from "../../contexts/DataProvider";
import {ProductImage} from "./components/ProductImage/ProductImage";
import {ProductDescription} from "./components/ProductDescription/ProductDescription";
import {Footer} from "../../components/Footer/Footer";

export const ProductDetails = () => {
    const {state} = useData();
    const {productId} = useParams();
    const {loading} = useData();
    const navigate = useNavigate();

    let selectedProduct = state.allProductsFromApi?.find(
        ({id}) => id === productId
    );

    if (!selectedProduct) {
        const savedProduct = localStorage.getItem("savedProduct");
        if (savedProduct) {
            selectedProduct = JSON.parse(savedProduct);
        } else {
            navigate("/")
        }
    }

    localStorage.setItem("savedProduct", JSON.stringify(selectedProduct));
    return (
        !loading && (
            <>
                <div className="products-page-container">
                    <ProductImage selectedProduct={selectedProduct}/>
                    <ProductDescription selectedProduct={selectedProduct}/>
                </div>
                <Footer/>
            </>
        )
    );
};
