// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.size-modal-container {
    position: fixed;
    inset: 0;
    z-index: 6;
    background-color: rgba(0, 0, 0, 0.2);
    margin: auto;
}

.size-secondary-btn-section {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    top: 10px;
}

.size-secondary-btn-section > * {
    margin: 0px 20px;
    color: var(--primary-color);
}

.size-secondary-btn-section > *:hover {
    color: black;
    cursor: pointer;
}

.size-modal-container .size-input-container {
    position: absolute;
    inset: 0;
    background-color: rgb(255, 255, 255);
    text-align: center;
    border-radius: 10px;
    width: 70%;
    margin: 20px auto auto;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 100%;
    overflow-y: scroll;
}

.size-input-container h1 {
    margin-top: 25px;
    color: rgb(33, 33, 33);
    margin-bottom: 25px;
}

.size-input-container img {
    width: 100%;
}

.size-modal-container .size-input-container input {
    padding: 8px 16px;
    display: block;
    width: 90%;
    margin: 12px auto;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ProductDetails/components/ProductModal/SizeModal.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,QAAQ;IACR,UAAU;IACV,oCAAoC;IACpC,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,yBAAyB;IACzB,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,oCAAoC;IACpC,kBAAkB;IAClB,mBAAmB;IACnB,UAAU;IACV,sBAAsB;IACtB,wBAAmB;IAAnB,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,UAAU;IACV,iBAAiB;IACjB,oCAAoC;IACpC,kBAAkB;AACtB","sourcesContent":["\n.size-modal-container {\n    position: fixed;\n    inset: 0;\n    z-index: 6;\n    background-color: rgba(0, 0, 0, 0.2);\n    margin: auto;\n}\n\n.size-secondary-btn-section {\n    display: flex;\n    align-items: flex-end;\n    justify-content: flex-end;\n    top: 10px;\n}\n\n.size-secondary-btn-section > * {\n    margin: 0px 20px;\n    color: var(--primary-color);\n}\n\n.size-secondary-btn-section > *:hover {\n    color: black;\n    cursor: pointer;\n}\n\n.size-modal-container .size-input-container {\n    position: absolute;\n    inset: 0;\n    background-color: rgb(255, 255, 255);\n    text-align: center;\n    border-radius: 10px;\n    width: 70%;\n    margin: 20px auto auto;\n    height: fit-content;\n    max-height: 100%;\n    overflow-y: scroll;\n}\n\n.size-input-container h1 {\n    margin-top: 25px;\n    color: rgb(33, 33, 33);\n    margin-bottom: 25px;\n}\n\n.size-input-container img {\n    width: 100%;\n}\n\n.size-modal-container .size-input-container input {\n    padding: 8px 16px;\n    display: block;\n    width: 90%;\n    margin: 12px auto;\n    border: 1px solid rgb(226, 226, 226);\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
