// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-card-container {
  padding: 1em;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.home-product-card {
  padding: 1em;
  box-shadow: 0px 0px 46px -4px rgb(238, 238, 238);
  margin: 1em 1em 2em 1em;
  border-radius: 1em;
  width: 230px;
  height: 330px;
  justify-content: space-between;
  display: flex;
  flex-flow: column nowrap;
}

.info {
  display: flex;
}

.info > * {
  margin-right: 1em;
}

.no-products-found {
}

.product-card-image {
  background-color: rgb(246, 246, 246);
  border-radius: 0.8em;
}

.product-card-image img {
  height: 230px;
  bottom: -2em;
  width: 140%;
  margin-left: -3em;
}

.product-card-details {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0.5em 0em;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  font-size: 0.9em;
  letter-spacing: 0.5px;
  line-height: 1.3;
  flex-grow: 1;
  z-index: -1;
}


@media (min-width: 768px) {
  .no-products-found {
    margin-left: 50px;
    margin-top: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/components/ProductSection/ProductRecommendationSection.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE,YAAY;EACZ,gDAAgD;EAChD,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,wBAAwB;AAC1B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;AACA;;AAEA;EACE,oCAAoC;EACpC,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,wBAAwB;EACxB,gBAAgB;EAChB,qBAAqB;EACrB,gBAAgB;EAChB,YAAY;EACZ,WAAW;AACb;;;AAGA;EACE;IACE,iBAAiB;IACjB,gBAAgB;EAClB;AACF","sourcesContent":[".product-card-container {\n  padding: 1em;\n  display: flex;\n  flex-flow: row wrap;\n  justify-content: space-evenly;\n}\n\n.home-product-card {\n  padding: 1em;\n  box-shadow: 0px 0px 46px -4px rgb(238, 238, 238);\n  margin: 1em 1em 2em 1em;\n  border-radius: 1em;\n  width: 230px;\n  height: 330px;\n  justify-content: space-between;\n  display: flex;\n  flex-flow: column nowrap;\n}\n\n.info {\n  display: flex;\n}\n\n.info > * {\n  margin-right: 1em;\n}\n\n.no-products-found {\n}\n\n.product-card-image {\n  background-color: rgb(246, 246, 246);\n  border-radius: 0.8em;\n}\n\n.product-card-image img {\n  height: 230px;\n  bottom: -2em;\n  width: 140%;\n  margin-left: -3em;\n}\n\n.product-card-details {\n  background-color: #ffffff;\n  border-radius: 10px;\n  padding: 0.5em 0em;\n  position: relative;\n  display: flex;\n  flex-flow: column nowrap;\n  font-size: 0.9em;\n  letter-spacing: 0.5px;\n  line-height: 1.3;\n  flex-grow: 1;\n  z-index: -1;\n}\n\n\n@media (min-width: 768px) {\n  .no-products-found {\n    margin-left: 50px;\n    margin-top: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
