import "./Header.css";
import {CgHeart} from "react-icons/cg";
import React from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {useState} from "react";
import {RxHamburgerMenu} from "react-icons/rx";
import {RxCross2} from "react-icons/rx";
import {useData} from "../../contexts/DataProvider";
import {useAuth} from "../../contexts/AuthProvider";
import {CgShoppingCart} from "react-icons/cg";
import {useUserData} from "../../contexts/UserDataProvider";

export const Header = () => {
    const {auth} = useAuth();
    const {dispatch} = useData();
    const navigate = useNavigate();
    const {userDataState, refPage} = useUserData();
    const [showHamburger, setShowHamburger] = useState(true);
    const [searchOn, setSearchOn] = useState('');
    const getActiveStyle = ({isActive}) => {
        return {color: isActive ? "#f29400" : ""};
    };

    const totalProductsInCart = userDataState.cartProducts?.reduce(
        (acc, curr) => {
            return acc + curr.qty;
        },
        0
    );

    const isProductInCart = () => (Number(totalProductsInCart) ? true : false);

    const totalProductsInWishlist = userDataState.wishlistProducts.length;

    const isProductInWishlist = () => (Number(totalProductsInWishlist) ? true : false);

    const handleScrollClick = () => {
        setShowHamburger(true)
        refPage.current?.scrollIntoView({behavior: 'smooth'});
    };

    return (
        <nav>
            <div className="nav-logo-home-button">
                <NavLink style={getActiveStyle} to="/">
                    <img alt={'logo_puppypop'} className="header_logo-img" src="/assets/icons/logo.svg"/>
                </NavLink>
            </div>

            <div
                className={
                    !showHamburger
                        ? "nav-link-container-mobile nav-link-container"
                        : "nav-link-container"
                }
            >
                <div className="nav-input-search">
                    <input className={`search-hover${searchOn}`}
                           onChange={(e) =>
                               dispatch({type: "SEARCH", payload: e.target.value})
                           }
                           onKeyDown={(e) => {
                               e.key === "Enter" && navigate("/product-listing");
                           }}
                           placeholder="Search"
                    />
                    <svg onClick={() => !searchOn ? setSearchOn(' search_on') : setSearchOn('')}
                         className='icon icon-search'
                         width='15px'
                         height='15px'
                         viewBox='0 0 15 15'
                         version='1.1'
                         xmlns='http://www.w3.org/2000/svg'>
                        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                            <g fillRule='nonzero' fill='#DBDBDB'>
                                <path
                                    d='M14.8163265,13.9346939 L11.2102041,10.3285714 C12.1040816,9.23877551 12.6428571,7.84285714 12.6428571,6.32142857 C12.6428571,2.83163265 9.81122449,0 6.32142857,0 C2.82857143,0 0,2.83163265 0,6.32142857 C0,9.81122449 2.82857143,12.6428571 6.32142857,12.6428571 C7.84285714,12.6428571 9.23571429,12.1071429 10.3255102,11.2132653 L13.9316327,14.8163265 C14.1765306,15.0612245 14.5714286,15.0612245 14.8163265,14.8163265 C15.0612245,14.5744898 15.0612245,14.1765306 14.8163265,13.9346939 Z M6.32142857,11.3846939 C3.52653061,11.3846939 1.25510204,9.11326531 1.25510204,6.32142857 C1.25510204,3.52959184 3.52653061,1.25510204 6.32142857,1.25510204 C9.11326531,1.25510204 11.3877551,3.52959184 11.3877551,6.32142857 C11.3877551,9.11326531 9.11326531,11.3846939 6.32142857,11.3846939 Z'></path>
                            </g>
                        </g>
                    </svg>
                </div>
                <NavLink
                    onClick={() => setShowHamburger(true)}
                    style={getActiveStyle}
                    to="/product-listing"
                    className="menu_item"
                >
                    SHOP
                </NavLink>
                <NavLink
                    onClick={handleScrollClick}
                    style={getActiveStyle}
                    className="menu_item"
                    to="/"
                >
                    ABOUT US
                </NavLink>
                <NavLink
                    onClick={() => setShowHamburger(true)}
                    style={getActiveStyle}
                    className="menu_item"
                    to="/sizes"
                >
                    SIZE GUIDE
                </NavLink>
                {(auth.isAuth || auth.is_admin) && <NavLink
                    onClick={() => setShowHamburger(true)}
                    style={getActiveStyle}
                    to="/admin"
                    className="menu_item"
                >
                    ADMIN
                </NavLink>}
                <NavLink
                    onClick={() => setShowHamburger(true)}
                    style={getActiveStyle}
                    to="/contacts"
                    className="menu_item"
                >
                    CONTACT
                </NavLink>
                <NavLink
                    onClick={() => setShowHamburger(true)}
                    style={getActiveStyle}
                    to="wishlist"
                >
                    <span>{!showHamburger ? "Wishlist" : ""}</span>
                    <CgHeart size={25} className="wishlist"/>{" "}
                    {isProductInWishlist() && (
                        <span className="cart-count cart-count-mobile">
              {totalProductsInWishlist}
            </span>
                    )}
                </NavLink>
                <NavLink
                    onClick={() => setShowHamburger(true)}
                    style={getActiveStyle}
                    to="/cart"
                >
                    <span>{!showHamburger ? "Cart" : ""}</span>
                    <CgShoppingCart size={25} className="cart"/>{" "}
                    {isProductInCart() && (
                        <span className="cart-count cart-count-mobile">
              {" "}
                            {totalProductsInCart}{" "}
            </span>
                    )}
                </NavLink>
            </div>
            {showHamburger && (
                <div className="hamburger-icon" onClick={() => setShowHamburger(false)}>
                    <RxHamburgerMenu size={20}/>
                </div>
            )}
            {!showHamburger && (
                <div
                    className="cross-tab-icon cross-tab-icon-mobile"
                    onClick={() => setShowHamburger(true)}
                >
                    <RxCross2 color={"rgb(106, 106, 65)"} size={25}/>
                </div>
            )}
        </nav>
    );
};
