import "./CategoryModal.css";
import React from "react";
import {useState} from "react";
import {toast} from "react-hot-toast";
import {useAuth} from "../../../../../../contexts/AuthProvider";
import {
    createCategoryService,
    updateCategoryService
} from "../../../../../../services/admin-services/adminCategoriesService";
import {useCategories} from "../../../../../../contexts/CategoriesProvider";


export const CategoryModal = () => {
    const [error, setError] = useState("");
    const {auth, logoutHandler} = useAuth();

    const {
        setIsCategoryModalOpen,
        categoryForm,
        setCategoryForm,
        isEdit,
        setIsEdit,
        getAdminCategoriesHandler
    } = useCategories();

    const updateCategory = async (category) => {
        try {
            setError("");
            const response = await updateCategoryService(auth.token, auth.token_type, category);

            setCategoryForm({categoryName: "", img: null});
            setIsCategoryModalOpen(false);
            setIsEdit(false);
            await getAdminCategoriesHandler()
            toast.success(`category updated successfully!`);

        } catch (error) {
            let error_msg = ""
            if (Array.isArray(error.response?.data?.detail)) {
                error.response?.data?.detail.forEach((err) => {
                        toast.error(err.msg);
                        error_msg += err.msg + " ";
                    }
                );
                setError(`category update failed! \n${error_msg}`);
            } else {
                toast.error(`category update failed! \n${error.response?.data?.detail?.error}`);
                setError(error.response?.data?.detail?.error);
                if (error.response?.status === 401) {
                    logoutHandler();
                }
            }
        }
    };

    const addCategory = async (category) => {
        try {
            setError("");
            const response = await createCategoryService(auth.token, auth.token_type, category);
            setIsCategoryModalOpen(false);
            await getAdminCategoriesHandler()
            setCategoryForm({categoryName: "", img: null});
            toast.success("New category added successfully!");

        } catch (error) {
            let error_msg = ""
            if (Array.isArray(error.response?.data?.detail)) {
                error.response?.data?.detail.forEach((err) => {
                        toast.error(err.msg);
                        error_msg += err.msg + " ";
                    }
                );
                setError(`category add failed! \n${error_msg}`);
            } else {
                toast.error(`category add failed! \n${error.response?.data?.detail?.error}`);
                setError(error.response?.data?.detail?.error);
                if (error.response?.status === 401) {
                    logoutHandler();
                }
            }
        }
    }

    return (
        <div className="category-modal-container">
            <div className="category-input-container">
                <h1>{(isEdit) ? "Category Form" : "New category form"}</h1>
                <form
                    onSubmit={(e) => {
                        if (!isEdit) {
                            e.preventDefault();
                            addCategory(categoryForm)
                        } else {
                            e.preventDefault();
                            updateCategory(categoryForm)
                        }
                    }}
                    className="input-containter"
                >
                    <input
                        name="title"
                        required
                        value={categoryForm.categoryName}
                        onChange={(e) =>
                            setCategoryForm({...categoryForm, categoryName: e.target.value})
                        }
                        placeholder="Enter Category Title"
                    />
                    {error && <span className="error">{error}</span>}
                    <div className="btn-container">
                        <button className="cancel" onClick={() => setIsCategoryModalOpen(false)}>Cancel</button>
                        <input className="submit" type="submit" value="Save"/>
                    </div>
                </form>
            </div>
        </div>
    );
};