import React from "react";
import {Footer} from "../../components/Footer/Footer";
import {useData} from "../../contexts/DataProvider";
import {PolicySection} from "./components/PolicySection/PolicySection";
import {TermsSection} from "./components/TermsSection/TermsSection";
import {RefundSection} from "./components/RefundSection/RefundSection";
import {ShippingSection} from "./components/ShippingSection/ShippingSection";
import {SizesSection} from "./components/SizesSection/SizesSection";

export const Privacy = () => {
    const {loading} = useData();
    return (
        !loading && (
            <div className="home-page">
                <div className="hero">
                    <PolicySection/>
                    <Footer/>
                </div>
            </div>
)
);
};

export const Terms = () => {
    const {loading} = useData();
    return (
        !loading && (
            <div className="home-page">
                <div className="hero">
                    <TermsSection/>
                    <Footer/>
                </div>
            </div>
)
);
};

export const Refund = () => {
    const {loading} = useData();
    return (
        !loading && (
            <div className="home-page">
                <div className="hero">
                    <RefundSection/>
                    <Footer/>
                </div>
            </div>
)
);
};

export const Shipping = () => {
    const {loading} = useData();
    return (
        !loading && (
            <div className="home-page">
                <div className="hero">
                    <ShippingSection/>
                    <Footer/>
                </div>
            </div>
)
);
};

export const Sizes = () => {
    const {loading} = useData();
    return (
        !loading && (
            <div className="home-page">
                <div className="hero">
                    <SizesSection/>
                    <Footer/>
                </div>
            </div>
)
);
};
