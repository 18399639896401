import React, {useEffect} from "react";
import Moment from 'moment';
import {useUserData} from "../../../contexts/UserDataProvider";
import "./Orders.css";

export const Orders = () => {
    const {userDataState, getOrdersListHandler} = useUserData();

    useEffect(() => {
        getOrdersListHandler();
    }, []);

    return !userDataState.orders?.length ? (
        <div className="orders-container">No Orders</div>
    ) : (
        <div className="orders-container">
            {userDataState.orders?.map(
                ({
                     id,
                     order_id,
                     date_created,
                     total,
                     address,
                     order_items,
                     status
                 }) => (
                    <div key={id} className="ordered-items-card">
                        <div className="order-id-container">
                            <span>Date: </span>
                            <span>{Moment(date_created).format('YYYY-MM-DD')}</span>
                            <span style={{
                                color: (status === 'succeeded') ? 'green' : 'orange',
                                float: 'right'
                            }}>{status}</span>
                        </div>
                        <div className="payment-id-container">
                            <span>Order ID: </span>
                            <span>{order_id}</span>
                        </div>
                        <div className="price-container">
                            <span>Amount: </span>
                            <span>${total}</span>
                        </div>
                        <div className="price-container">
                            <span>Delivery-Address:</span>
                            <span>
                {address?.street} {address?.state}{" "}
                                {address?.country}
              </span>
                        </div>
                        <div className="price-container">
                            <span>Contact:</span>
                            <span>
                {address?.first_name} {address?.last_name}{" "}
                                {address?.phone}
              </span>
                        </div>
                        <div className="products-container">
                            {order_items?.map(
                                ({id, product, total, qty}) => (
                                    <div className="products-card" key={id}>
                                        <img src={`/api/uploaded/${product.img}`} alt={product.name}/>
                                        <div className="description">
                                            <span className="name">Name: {product.name}</span>
                                            <span className="qty">Qty: {qty}</span>
                                            <span className="price">Price: ${total}</span>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                )
            )}
        </div>
    );
};
