import {createContext, useContext, useEffect, useReducer, useState} from "react";
import {adminDataReducer, initialProducts} from "../reducer/adminDataReducer";
import {useAuth} from "./AuthProvider";
import {getAdminProductList} from "../services/admin-services/adminProductsService";
import {getAdminCategories} from "../services/admin-services/adminCategoriesService";

const ProductsContext = createContext();

export function ProductsProvider({children}) {

    const {auth} = useAuth();
    const [loading, setLoading] = useState(false);
    const [productForm, setProductForm] = useState({
        id: "",
        name: "",
        sizes: [],
        category_name: null,
        category_id: null,
        is_stock: false,
        description: "",
        trending: false,
        background_color: null,
        img: null,
        photos: [],
        materials: "",
        care: "",
    });


    const [sizeForm, setSizerForm] = useState({
        size: 1,
        original_price: 0,
        discounted_price: 0
    });

    const [editProductIndex, setEditProductIndex] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isProductModalOpen, setIsProductModalOpen] = useState(false);
    const [productsDataState, dispatch] = useReducer(adminDataReducer, initialProducts);

    const getAdminCategoriesHandler = async () => {
        try {
            const response = await getAdminCategories(auth.token, auth.token_type);
            dispatch({type: "GET_SELECT_CATEGORIES", payload: [...response.data.categories]});
        } catch (error) {
            console.error(error);
        }
    };

    const getAdminProducts = async () => {
        if (!auth.isAuth || !auth.isAdmin) return;
        try {
            const response = await getAdminProductList(auth.token, auth.token_type);
            dispatch({type: "GET_ALL_PRODUCTS", payload: [...response.data.products]});
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setLoading(true);
        getAdminProducts();
        setLoading(false);
    }, [auth.isAdmin]);

    return (
        <ProductsContext.Provider
            value={{
                dispatch,
                loading,
                setLoading,
                editProductIndex,
                setEditProductIndex,
                productForm,
                setProductForm,
                sizeForm,
                setSizerForm,
                isProductModalOpen,
                setIsProductModalOpen,
                isEdit,
                setIsEdit,
                productsDataState,
                getAdminProducts,
                getAdminCategoriesHandler
            }}
        >
            {children}
        </ProductsContext.Provider>
    );
}

export const useProducts = () => useContext(ProductsContext);
