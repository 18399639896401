// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-heading-container {
    margin: 4rem;
    text-align: center;
}

.home-title {
    font-size: 3em;
    background: #000000;
    background: linear-gradient(to right, #000000 0%, #5e5e5e 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.home-subtitle {
    line-height: 1.5;
    font-weight: inherit;
    color: grey;
}

.home-link {
    line-height: 1.5;
    color: #ff8562;
    text-decoration: none
}


@media (max-width: 840px) {

    .home-heading-container {
        margin: 2rem;
    }

    .home-title {
        font-size: 3em;
    }

    .home-subtitle {
        font-size: 1.3em;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/components/HeroSection/HeroSection.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,+DAA+D;IAC/D,6BAA6B;IAC7B,oCAAoC;AACxC;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd;AACJ;;;AAGA;;IAEI;QACI,YAAY;IAChB;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".home-heading-container {\n    margin: 4rem;\n    text-align: center;\n}\n\n.home-title {\n    font-size: 3em;\n    background: #000000;\n    background: linear-gradient(to right, #000000 0%, #5e5e5e 100%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n}\n\n.home-subtitle {\n    line-height: 1.5;\n    font-weight: inherit;\n    color: grey;\n}\n\n.home-link {\n    line-height: 1.5;\n    color: #ff8562;\n    text-decoration: none\n}\n\n\n@media (max-width: 840px) {\n\n    .home-heading-container {\n        margin: 2rem;\n    }\n\n    .home-title {\n        font-size: 3em;\n    }\n\n    .home-subtitle {\n        font-size: 1.3em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
