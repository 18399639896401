import axios from "axios";

export const getAdminCategories = async () =>{
    return await axios.get("/api/categories");
}


export const createCategoryService = async (access_token, token_type, category) => {
    return await axios.post(
        "/api/admin/categories",
        {
            name: category.categoryName,
            img: category.img,
        },
        {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `${token_type} ${access_token}`,
            },
        }
    );
};


export const updateCategoryService = async (access_token, token_type, category) => {
    return await axios.patch(
        `/api/admin/categories/${category.id}`,
        {
            name: category.categoryName,
            img: category.img,
        },
        {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `${token_type} ${access_token}`,
            },
        }
    );
};


export const deleteCategoryService = async (access_token, token_type, category) => {
    return await axios.delete(
        `/api/admin/categories/${category.id}`,
        {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `${token_type} ${access_token}`,
            },
        }
    );
};