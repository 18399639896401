export const userDataReducer = (state, action) => {
  switch (action.type) {
    case "SET_CART": {
      return { ...state, cartProducts: [...action.payload] };
    }

    case "ADD_CART_ITEM": {
      return { ...state, cartProducts: [...state.cartProducts, action.payload] };
    }

    case "SET_ADDRESS": {
      return { ...state, addressList: [...action.payload] };
    }

    case "ADD_WISHLIST_ITEM": {
      return { ...state, wishlistProducts: [...state.wishlistProducts, action.payload] };
    }

    case "SET_WISHLIST": {
      return { ...state, wishlistProducts: [...action.payload] };
    }

    case "SET_DELIVERY": {
      return { ...state, countries: action.payload };
    }

    case "SET_LOCATION": {
      return { ...state, locationDetails: {
          ...state.locationDetails,
          ...action.payload,
        }
      };
    }

    case "SET_ORDER": {
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          ...action.payload,
        },
      };
    }

    case "SET_ORDERS": {
      return { ...state, orders: [...action.payload] };
    }

    case "GET_PRODUCT_DETAILS":
      return { ...state, product: {...action.payload} };

    default:
      return state;
  }
};

export const initialUserData = {
  cartProducts: [],
  wishlistProducts: [],
  addressList: [],
  countries: [],
  locationDetails: {
    city: "",
    state: "",
    country: "",
    postal: "",
    country_code: ""
  },
  orders: [],
  product: {},
  orderDetails: {
    cartItemsTotal: "",
    cartItemsDiscountTotal: "",
    couponDiscountTotal: "",
  },
};
