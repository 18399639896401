// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-clients-container {
    display: block;
}

.admin-client-card {
    display: block;
    box-shadow: 0 0 31px -3px rgba(232, 232, 232, 0.75);
    border-radius: 10px;
    padding: 10px;
    position: relative;
    flex-flow: column;
    align-items: center;
    max-height: 250px;
    margin: 10px;
    min-width: 200px;
}

.client-cell {
    display: inline-block;
    margin: 10px 10px 0 0;
    min-width: 100px;
    max-width: 100px;
    text-align: center;
}

.client-date {
    font-weight: 600;
}

.client-id {
    font-weight: 300;
    font-size: 5px;
}

.client-status {
    color: grey;
    font-size: small;
}

.client-user {
    font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/components/Clients/AdminClientsSection.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,mDAAmD;IACnD,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,qBAAqB;IACrB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".admin-clients-container {\n    display: block;\n}\n\n.admin-client-card {\n    display: block;\n    box-shadow: 0 0 31px -3px rgba(232, 232, 232, 0.75);\n    border-radius: 10px;\n    padding: 10px;\n    position: relative;\n    flex-flow: column;\n    align-items: center;\n    max-height: 250px;\n    margin: 10px;\n    min-width: 200px;\n}\n\n.client-cell {\n    display: inline-block;\n    margin: 10px 10px 0 0;\n    min-width: 100px;\n    max-width: 100px;\n    text-align: center;\n}\n\n.client-date {\n    font-weight: 600;\n}\n\n.client-id {\n    font-weight: 300;\n    font-size: 5px;\n}\n\n.client-status {\n    color: grey;\n    font-size: small;\n}\n\n.client-user {\n    font-size: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
