import axios from "axios";

export const uploadImageService = async (access_token, token_type, image) => {
    const file = new FormData();
    file.append("file", image);
    return await axios.post(
        "/api/storage/upload_temporary_file",
        file,
        {
            headers: {
                'Content-type': 'multipart/form-data',
                'Authorization': `${token_type} ${access_token}`,
            },
        }
    );
}

export const deleteImageService = async (access_token, token_type, image_id) => {
    return await axios.delete(
        `/api/storage/${image_id}`,
        {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `${token_type} ${access_token}`,
            },
        }
    );
}
