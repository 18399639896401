// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-gallery-thumbnails-container {
  display: contents;
}

.image-gallery-image {
  max-width: 500px;
}

.product-details-image img {
  width: 90vw;
  object-fit: cover;
  height: 65vw;
  margin-bottom: 50px;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .product-details-image img {
    width: auto;
    object-fit: cover;
    height: 500px;
    margin-top: -70px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/ProductDetails/components/ProductImage/ProductImage.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE;IACE,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,iBAAiB;EACnB;AACF","sourcesContent":[".image-gallery-thumbnails-container {\n  display: contents;\n}\n\n.image-gallery-image {\n  max-width: 500px;\n}\n\n.product-details-image img {\n  width: 90vw;\n  object-fit: cover;\n  height: 65vw;\n  margin-bottom: 50px;\n  margin-top: 30px;\n}\n\n@media (min-width: 768px) {\n  .product-details-image img {\n    width: auto;\n    object-fit: cover;\n    height: 500px;\n    margin-top: -70px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
