import React from "react";
import "../../PolicySection.css";


export const ShippingSection = () => {
    return (
        <>
            <div className="hero-heading-container">
                <h1 className="hero-title">Shipping policy</h1>
                <br></br>
                <div className="hero-subtitle">
                    <br/><br/>

                    <p>
                        All orders are shipped from Belgium. We have an estimated shipping time of 3 to 14 business days
                        after purchase confirmation and payment verification. The delivery time could vary depending on
                        your location and in case of any issues with our carrier or in customs. Please take into account
                        that you will assume any import or local taxes according to regulations for each country.
                        <br/><br/>
                        We will send you an email with the carrier information and tracking number so you can keep
                        updated on the state of the delivery.
                    </p>
                    <br/>
                </div>

            </div>
        </>
    );
};
