export const initialProducts = {
    products: [],
    categories: []
}

export const initialCategories = {
    categories: []
}

export const initialClients = {
    users: []
}

export const initialOrders = {
    orders: []
}

export const adminDataReducer = (state, action) => {
    switch (action.type) {
        case "GET_ALL_PRODUCTS":
            return {...state, products: action.payload};
        case "GET_ALL_CATEGORIES":
            return {...state, categories: action.payload};
        case "GET_SELECT_CATEGORIES":
            const items = action.payload.map((item) => {
                return {value: item.id, label: item.categoryName}
            });
            return {...state, categories: items};
        case "GET_ALL_USERS":
            return {...state, users: action.payload};
        case "GET_ALL_ORDERS":
            return {...state, orders: action.payload};
        case "GET_PRODUCT_DETAILS":
            return {...state, product: action.payload};
        default:
            return state;
    }
};