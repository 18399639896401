import axios from "axios";

export const getAdminProductList = async (access_token, token_type) => {
    return await axios.get("/api/admin/products", {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `${token_type} ${access_token}`,
            },
        }
    );
};

export const createProductService = async (access_token, token_type, product) => {
    return await axios.post(
        "/api/admin/products",
        {
            name: product.name,
            category_id: product.category_id,
            is_stock: product.is_stock,
            description: product.description,
            trending: product.trending,
            sizes: product.sizes,
            background_color: product.background_color,
            img: (product.img.includes('blob') ? product.img_file : product.img),
            photos: product.photos,
            materials: product.materials,
            care: product.care,
        },
        {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `${token_type} ${access_token}`,
            },
        }
    );
};

export const updateProductService = async (access_token, token_type, product) => {

    return await axios.patch(
        `/api/admin/products/${product.id}`,
        {
            name: product.name,
            category_id: product.category_id,
            is_stock: product.is_stock,
            description: product.description,
            trending: product.trending,
            sizes: product.sizes,
            background_color: product.background_color,
            img: (product.img.includes('blob') ? product.img_file : product.img),
            photos: product.photos,
            materials: product.materials,
            care: product.care,
        },
        {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `${token_type} ${access_token}`,
            },
        }
    );
};
