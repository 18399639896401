import "./AdminOrdersSection.css";
import React, {useEffect} from "react";
import {AiOutlineEdit, AiOutlineEye} from "react-icons/ai";
import Moment from "moment/moment";
import {useOrders} from "../../../../contexts/OrdersProvider";
import {OrderModal} from "./components/OrderModal";

export const AdminOrdersSection = () => {
    const {
        auth,
        setOrderForm,
        isOrderModalOpen,
        setIsOrderModalOpen,
        ordersDataState,
        getAdminOrders
    } = useOrders();


    useEffect(() => {
        getAdminOrders();
    }, [auth]);

    const viewOrderHandler = (order) => {
        setOrderForm(order);
        setIsOrderModalOpen(true);
    };

    return (
        <div style={{width: "100%"}}>
            <div className="admin-orders-container">
                <div className="admin-order-card">
                    <div className="order-cell order-date">
                        <span>Date Created</span>
                    </div>
                    <div className="order-cell">
                        <span>Status</span>
                    </div>
                    <div className="order-cell">
                        <span>Order ID</span>
                    </div>
                    <div className="order-cell">
                        <span>UserID</span>
                    </div>
                    <div className="order-cell">
                        <span>Contact</span>
                    </div>
                    <div className="order-cell">
                        <span>Phone</span>
                    </div>
                    <div className="order-cell order-date">
                        <span>Amount</span>
                    </div>
                </div>
                {ordersDataState.orders?.map((order) => (
                    <div className="admin-order-card" key={order.id}>
                        <div className="order-cell order-date">
                            <span>{Moment(order.date_created).format('YYYY-MM-DD')}</span>
                        </div>
                        <div className="order-cell order-status">
                                <span style={{color: (order.status === 'delivered') ? 'green' : 'orange'}}>
                                    {order.status}
                                </span>
                        </div>
                        <div className="order-cell">
                            <span>{order.order_id}</span>
                        </div>
                        <div className="order-cell order-user">
                            <span>{order.user_id}</span>
                        </div>
                        <div className="order-cell">
                            <span>{order.address?.first_name} {order.address?.last_name}</span>
                        </div>
                        <div className="order-cell">
                            <span>{order.address?.phone}</span>
                        </div>
                        <div className="order-cell order-date">
                            <span>{order.total} $</span>
                        </div>
                        <div className="order-cell">
                            <AiOutlineEye size={25}
                                          onClick={() => viewOrderHandler(order)}
                            />
                        </div>
                        <div className="order-cell">
                            <AiOutlineEdit size={25}/>
                        </div>
                    </div>
                ))}
            </div>
            {isOrderModalOpen && <OrderModal/>}
        </div>
    );
};
