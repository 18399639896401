import {createContext, useContext, useEffect, useReducer, useState} from "react";
import {adminDataReducer, initialCategories} from "../reducer/adminDataReducer";
import {useAuth} from "./AuthProvider";
import {deleteCategoryService, getAdminCategories} from "../services/admin-services/adminCategoriesService";
import {toast} from "react-hot-toast";

const CategoriesContext = createContext();

export function CategoriesProvider({children}) {

    const {auth} = useAuth();
    const [loading, setLoading] = useState(false);
    const [categoryForm, setCategoryForm] = useState({
        id: "",
        categoryName: "",
        img: "",
    });

    const [editCategoryIndex, setEditCategoryIndex] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
    const [categoriesDataState, dispatch] = useReducer(adminDataReducer, initialCategories);

    const getAdminCategoriesHandler2 = async () => {
        if (!auth.isAuth || !auth.isAdmin) return;
        try {
            setLoading(true);
            const response = await getAdminCategories(auth.token, auth.token_type);
            if (response.status === 200) {
                dispatch({type: "GET_ALL_CATEGORIES", payload: [...response.data.categories]});
            } else {
                setLoading(false);
                console.error("getAdminCategoriesHandler : ", response.data)
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };


    const deleteCategory = async (category) => {
        try {
            const response = await deleteCategoryService(auth.token, auth.token_type, category);
            if (response.status === 200) {
                await getAdminCategoriesHandler2()
                toast.success("category deleted successfully!");
            } else {
                console.error("deleteCategory : ", response.data)
            }
        } catch (error) {
            console.error(error);
            toast.error(`category delete failed! ${error}`);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const getAdminCategoriesHandler = async () => {
            if (!auth.isAuth || !auth.isAdmin) return;
            try {
                setLoading(true);
                const response = await getAdminCategories(auth.token, auth.token_type);
                if (response.status === 200) {
                    dispatch({type: "GET_ALL_CATEGORIES", payload: [...response.data.categories]});
                } else {
                    setLoading(false);
                    console.error("getAdminCategoriesHandler : ", response.data)
                }
            } catch (error) {
                setLoading(false);
                console.error(error);
            }
        };
        getAdminCategoriesHandler();
    }, [auth]);

    return (
        <CategoriesContext.Provider
            value={{
                dispatch,
                loading,
                setLoading,
                editCategoryIndex,
                setEditCategoryIndex,
                categoryForm,
                setCategoryForm,
                isCategoryModalOpen,
                setIsCategoryModalOpen,
                isEdit,
                setIsEdit,
                categoriesDataState,
                getAdminCategoriesHandler2,
                deleteCategory
            }}
        >
            {children}
        </CategoriesContext.Provider>
    );
}

export const useCategories = () => useContext(CategoriesContext);
