// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-heading-container {
    margin: 10rem;
    margin-top: 2rem;
    text-align: center;

    .size-img {
        width: 100%;
        height: auto;
    }
}

.hero-title {
    text-align: center;
}

.hero-subtitle {
    line-height: 1.5;
    font-weight: inherit;
    color: grey;
    text-align: justify;
}

.hero-link {
    line-height: 1.5;
    color: #ff8562;
    text-decoration: none
}

@media (min-width: 768px) {
    .hero-title {
        font-size: 2em;
    }

    .hero-subtitle {
        font-size: 1em;
        text-align: justify;
    }
}

@media (max-width: 768px) {
    .hero-heading-container {
        margin: 1.5rem;
        margin-top: 1rem;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Policies/PolicySection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,kBAAkB;;IAElB;QACI,WAAW;QACX,YAAY;IAChB;AACJ;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd;AACJ;;AAEA;IACI;QACI,cAAc;IAClB;;IAEA;QACI,cAAc;QACd,mBAAmB;IACvB;AACJ;;AAEA;IACI;QACI,cAAc;QACd,gBAAgB;IACpB;AACJ","sourcesContent":[".hero-heading-container {\n    margin: 10rem;\n    margin-top: 2rem;\n    text-align: center;\n\n    .size-img {\n        width: 100%;\n        height: auto;\n    }\n}\n\n.hero-title {\n    text-align: center;\n}\n\n.hero-subtitle {\n    line-height: 1.5;\n    font-weight: inherit;\n    color: grey;\n    text-align: justify;\n}\n\n.hero-link {\n    line-height: 1.5;\n    color: #ff8562;\n    text-decoration: none\n}\n\n@media (min-width: 768px) {\n    .hero-title {\n        font-size: 2em;\n    }\n\n    .hero-subtitle {\n        font-size: 1em;\n        text-align: justify;\n    }\n}\n\n@media (max-width: 768px) {\n    .hero-heading-container {\n        margin: 1.5rem;\n        margin-top: 1rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
