import "./AdminClientsSection.css";
import React, {useEffect, useReducer} from "react";
import Moment from "moment/moment";
import {useAuth} from "../../../../contexts/AuthProvider";
import {adminDataReducer, initialClients} from "../../../../reducer/adminDataReducer";
import {getAdminClientsList} from "../../../../services/admin-services/adminClientsService";
import toast from "react-hot-toast";
import {useLocation, useNavigate} from "react-router-dom";

export const AdminClientsSection = () => {
    const [state, dispatch] = useReducer(adminDataReducer, initialClients);
    const {auth, setAuth} = useAuth();

    const navigate = useNavigate();
    const location = useLocation();


    const handleLogout = () => {
        localStorage.removeItem("isAuth");
        localStorage.removeItem("access_token");
        localStorage.removeItem("token_type");
        localStorage.removeItem("user_id");
        setAuth({token: "", isAuth: false});
        dispatch({type: "SET_CART", payload: []});
        dispatch({type: "SET_WISHLIST", payload: []});
    };

    const getAdminUsersHandler = async () => {
        try {
            const response = await getAdminClientsList(auth.token, auth.token_type);
            dispatch({type: "GET_ALL_USERS", payload: [...response.data.users]});
        } catch (error) {
            if (error.response?.status === 401) {
                handleLogout();
                toast.error("Your session expired. Please login again!");
                navigate("/login", {state: {from: location}});
            }
            console.error(error.response);
        }
    };

    useEffect(() => {
        getAdminUsersHandler();
    }, [auth]);


    return (
        <div style={{width: "100%"}}>
            <div className="admin-clients-container">
                <div className="admin-client-card">
                    <div className="client-cell client-date">
                        <span>Date Created</span>
                    </div>
                    <div className="client-cell">
                        <span>Status</span>
                    </div>
                    <div className="client-cell">
                        <span>client ID</span>
                    </div>
                    <div className="client-cell">
                        <span>Email</span>
                    </div>
                    <div className="client-cell">
                        <span>Name</span>
                    </div>
                    <div className="client-cell">
                        <span>Last Login</span>
                    </div>
                    <div className="client-cell">
                        <span>Wishlist Qty</span>
                    </div>
                    <div className="client-cell">
                        <span>Orders Qty</span>
                    </div>
                    <div className="client-cell">
                        <span>TOTAL</span>
                    </div>
                </div>
                {state.users?.map(
                    ({
                         id,
                         first_name,
                         last_name,
                         email,
                         date_created,
                         date_updated,
                         date_login,
                         is_admin,
                         orders_qty,
                         wishlist_qty,
                         orders_total_sum,
                     }) => (
                        <div className="admin-client-card" key={id}>
                            <div className="client-cell client-date">
                                <span>{Moment(date_created).format('YYYY-MM-DD')}</span>
                            </div>
                            <div className="client-cell client-status">
                                <span
                                    style={{color: (is_admin) ? 'green' : 'grey'}}>{(is_admin) ? 'ADMIN' : 'client'}</span>
                            </div>
                            <div className="client-cell  client-id">
                                <span>{id}</span>
                            </div>
                            <div className="client-cell client-user">
                                <span>{email}</span>
                            </div>
                            <div className="client-cell">
                                <span>{first_name} {last_name}</span>
                            </div>
                            <div className="client-cell client-date">
                                <span>{(date_created) ? Moment(date_created).format('YYYY-MM-DD') : "-"}</span>
                            </div>
                            <div className="client-cell client-date">
                                <span>{wishlist_qty}</span>
                            </div>
                            <div className="client-cell">
                                <span>{orders_qty}</span>
                            </div>
                            <div className="client-cell client-date">
                                <span>{orders_total_sum}</span>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};
