import "./Payment.css";

import {useUserData} from "../../contexts/UserDataProvider";
import {useNavigate, useParams} from "react-router-dom";
import {useData} from "../../contexts/DataProvider";
import React, {useEffect} from "react";
import {getSuccessPayment} from "../../services/payment-services/addPaymentService";

export const Payment = () => {
    const {dispatch} = useUserData();
    const {sessionId} = useParams();
    const navigate = useNavigate();
    const {loading, setLoading} = useData();
    const [isSuccess, setIsSuccess] = React.useState(true);

    const handleCheckoutSessionId = async() => {
        setLoading(true);
        try {
            const response = await getSuccessPayment(sessionId);
            if (response.status === 200) {
                setIsSuccess(true);
                dispatch({type: "SET_CART", payload: []});
                localStorage.setItem("puppyPopCart", JSON.stringify([]));
            }
        } catch (error) {
            console.error("Error", error)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleCheckoutSessionId();
    }, []);


    return (
        !loading &&
        (isSuccess ? (
            <div>
                <div className="payment-container">
                    <div className='payment-summary'>
                        <div className="success-payment-container">
                            <div
                                style={{
                                    borderRadius :"200px",
                                    height:"200px",
                                    width:"200px",
                                    background: "#F8FAF5",
                                    margin:"0 auto"
                            }}>
                                <i className="checkmark">✓</i>
                            </div>
                            <h1>Success</h1>
                            <p>Thank you for your purchase request!<br/> We will get in touch with you shortly.</p>
                            <button onClick={() => navigate("/")} className="success-payment-btn">
                                Go to main page
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className="no-items-container">
                <h2 className="page-heading">Page not Found</h2>
                <button
                    className="success-payment-btn"
                    onClick={() => navigate("/product-listing")}
                >
                    SHOP
                </button>
            </div>
        ))
    );
};
