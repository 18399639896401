import React from "react";

import "./HeroSection.css";

export const HeroSection = () => {
    return (
        <>
            <div className="home-heading-container">
                <h1 className="home-title">Collection</h1>
                <br></br>
                <h3 className="home-subtitle">
                    We are
                    passionate with the wonderful world of colors that will
                    <br></br>
                    be changed depending on the season and our mood.
                    <br></br>
                    <br></br>
                    Choosing our products you will gain a puppy pop twist.
                    <br></br>
                    Designed and made on order in Belgium.
                    <br></br>
                    <br></br>
                </h3>
            </div>
        </>
    );
};
