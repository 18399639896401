// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cart-container {
  display: flex;
  flex-direction: column;
  margin: 15px 30px;
}

div .explore-btn {
  text-align: center;
  font-weight: 800;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  color: white;
  background-color: var(--primary-color);
  padding: 0.5rem 2rem;
}
.no-items-container {
  text-align: center;
}

.no-items-container button {
  cursor: pointer;
  background-color: black;
}

.page-heading {
  text-align: center;
}

@media (min-width: 768px) {
  .cart-container {
    flex-direction: column;
  }

  .cart-summary {
    display: flex;
    justify-content: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Cart/Cart.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,sCAAsC;EACtC,oBAAoB;AACtB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,uBAAuB;EACzB;AACF","sourcesContent":[".cart-container {\n  display: flex;\n  flex-direction: column;\n  margin: 15px 30px;\n}\n\ndiv .explore-btn {\n  text-align: center;\n  font-weight: 800;\n  border: none;\n  border-radius: 20px;\n  font-size: 1rem;\n  color: white;\n  background-color: var(--primary-color);\n  padding: 0.5rem 2rem;\n}\n.no-items-container {\n  text-align: center;\n}\n\n.no-items-container button {\n  cursor: pointer;\n  background-color: black;\n}\n\n.page-heading {\n  text-align: center;\n}\n\n@media (min-width: 768px) {\n  .cart-container {\n    flex-direction: column;\n  }\n\n  .cart-summary {\n    display: flex;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
