import axios from "axios";

export const addToWishlistService = async (product, access_token, token_type) => {
  return await axios.post(
    "/api/user/wishlist",
    { product_id: product.id },
    {
      headers: {
        'Content-type': 'application/json',
        'Authorization': `${token_type} ${access_token}`,
    },
    }
  );
};
