import React from "react";
import "../../PolicySection.css";


export const PolicySection = () => {
    return (
        <>
            <div className="hero-heading-container">
                <h1 className="hero-title">Privacy policy</h1>
                <br></br>
                <div className="hero-subtitle">
                    <div><p
                        dir="auto">This Privacy Policy describes how your personal information is collected, used, and
                        shared when you visit or make a purchase from puppypop.be (the “Site”).</p><br/>
                        <p dir="auto"><strong>Personal information we collect</strong><br/>When you visit the Site, we
                            automatically collect certain information about your device, including information about
                            your web browser, IP address, time zone, and some of the cookies that are installed on your
                            device. Additionally, as you browse the Site, we collect information about the individual
                            web pages or products that you view, what websites or search terms referred you to the Site,
                            and information about how you interact with the Site. We refer to this
                            automatically-collected information as “Device Information”.</p>
                        <p dir="auto">We collect Device Information using the following technologies:</p>
                        <br/>
                        <ul style={{padding: "20px"}}>
                            <li>“Cookies” are data files that are placed on your device or computer and often include an
                                anonymous unique identifier. For more information about cookies, and how to disable
                                cookies, visit: <a href="http://www.allaboutcookies.org" target="_blank"
                                                   rel="noreferrer ugc nofollow">
                                    <div><span><span><span
                                        aria-expanded="false" aria-haspopup="true"
                                        data-testid="hover-card-trigger-wrapper"><a
                                        href="http://www.allaboutcookies.org/" tabIndex="0" role="button"
                                        data-testid="inline-card-resolved-view"><span
                                        data-testid="inline-card-icon-and-title"><span><span className="css-w2w4jx"></span><img
                                        style={{maxWidth: "16px"}}
                                        src="//cdn.allaboutcookies.org/images/2022/05/04/favicon.png"
                                        data-testid="inline-card-icon-and-title-image"/></span><span
                                        >All About Cookies | Online Privacy and Digital Security</span></span></a></span></span></span>
                                    </div>
                                </a>
                            </li>
                            <li>“Log files” track actions occurring on the Site, and collect data including your IP
                                address, browser type, Internet service provider, referring/exit pages, and date/time
                                stamps.
                            </li>
                            <li>“Web beacons”, “tags”, and “pixels” are electronic files used to record information
                                about how you browse the Site.
                            </li>
                        </ul>
                        <p dir="auto">Additionally, when you make a purchase or attempt to make a purchase through the
                            Site, we collect certain information from you, including your name, billing address,
                            shipping address, payment information (including credit card numbers), email address, and
                            phone number. We refer to this information as “Order Information”.</p>
                        <p dir="auto">When we talk about “Personal Information” in this Privacy Policy, we are talking
                            both about Device Information and Order Information.</p>
                        <br/>
                        <p dir="auto"><strong>How do we use your personal information?</strong><br/>We use the Order
                            Information that we collect generally to fulfill any orders placed through the Site
                            (including processing your payment information, arranging for shipping, and providing you
                            with invoices and/or order confirmations). Additionally, we use this Order Information to:
                        </p>
                        <ul style={{padding: "20px"}}>
                            <li>Communicate with you;</li>
                            <li>Screen our orders for potential risk or fraud; and</li>
                            <li>When in line with the preferences you have shared with us, provide you with information
                                or advertising relating to our products or services.
                            </li>
                        </ul>
                        <p dir="auto">We use the Device Information that we collect to help us screen for potential risk
                            and fraud (in particular, your IP address), and more generally to improve and optimize our
                            Site (for example, by generating analytics about how our customers browse and interact with
                            the Site, and to assess the success of our marketing and advertising campaigns).</p><br/>
                        <p dir="auto"><strong>Sharing your Personal Information</strong><br/>We share your Personal
                            Information with third parties to help us use your Personal Information, as described above.
                            For example, we use Stripe to power our online store--you can read more about how Stripe
                            uses your Personal Information here:
                            <a href="https://stripe.com/privacy" target="_blank" rel="noreferrer ugc nofollow">
                                <div><span><span>
                                    <span aria-expanded="false" aria-haspopup="true"
                                          data-testid="hover-card-trigger-wrapper">
                                    <a
                                        href="https://stripe.com/privacy" tabIndex="0" role="button"
                                        data-testid="inline-card-resolved-view"><span
                                        data-testid="inline-card-icon-and-title"><span><span></span>
                                        <img
                                            style={{maxWidth: "32px"}}
                                            src="https://logos-world.net/wp-content/uploads/2021/03/Stripe-Logo-700x394.png"
                                            data-testid="inline-card-icon-and-title-image"/>
                                        </span><span>Stripe Privacy Policy</span></span></a></span></span></span>
                                </div>
                            </a>
                            <br/> We also use Google Analytics to help us understand how our customers use
                            the Site -- you can read more about how Google uses your Personal Information here: <a
                                href="https://www.google.com/intl/en/policies/privacy/" target="_blank"
                                rel="noreferrer ugc nofollow">
                                <div><span ><span><span
                                    aria-expanded="false" aria-haspopup="true" data-testid="hover-card-trigger-wrapper"><a
                                    href="https://www.google.com/intl/en/policies/privacy/" tabIndex="0" role="button"
                                    data-testid="inline-card-resolved-view"><span
                                    data-testid="inline-card-icon-and-title"><span><span ></span><img
                                    style={{maxWidth: "16px"}}
                                    src="https://ssl.gstatic.com/policies/favicon.ico"
                                    data-testid="inline-card-icon-and-title-image"/></span>
                                    <span>Privacy Policy – Privacy &amp; Terms – Google</span></span></a></span></span>
                                </span>
                                </div>
                            </a><br/> You can also opt-out of Google Analytics here: <a
                                href="https://tools.google.com/dlpage/gaoptout" target="_blank"
                                rel="noreferrer ugc nofollow">
                                <div><span><span><span
                                    aria-expanded="false" aria-haspopup="true" data-testid="hover-card-trigger-wrapper"><a
                                    href="https://tools.google.com/dlpage/gaoptout" tabIndex="0" role="button"
                                    data-testid="inline-card-resolved-view"><span
                                    data-testid="inline-card-icon-and-title"><span><span></span><span><span data-testid="inline-card-icon-and-title-default"
                                                                  role="img" aria-label="link"><svg width="16" height="16"
                                                                                               viewBox="0 0 16 16"
                                                                                               role="presentation"><g
                                    fill="currentColor" fill-rule="evenodd"><path
                                    d="M12.856 5.457l-.937.92a1.002 1.002 0 000 1.437 1.047 1.047 0 001.463 0l.984-.966c.967-.95 2.542-1.135 3.602-.288a2.54 2.54 0 01.203 3.81l-2.903 2.852a2.646 2.646 0 01-3.696 0l-1.11-1.09L9 13.57l1.108 1.089c1.822 1.788 4.802 1.788 6.622 0l2.905-2.852a4.558 4.558 0 00-.357-6.82c-1.893-1.517-4.695-1.226-6.422.47"></path><path
                                    d="M11.144 19.543l.937-.92a1.002 1.002 0 000-1.437 1.047 1.047 0 00-1.462 0l-.985.966c-.967.95-2.542 1.135-3.602.288a2.54 2.54 0 01-.203-3.81l2.903-2.852a2.646 2.646 0 013.696 0l1.11 1.09L15 11.43l-1.108-1.089c-1.822-1.788-4.802-1.788-6.622 0l-2.905 2.852a4.558 4.558 0 00.357 6.82c1.893 1.517 4.695 1.226 6.422-.47"></path></g></svg></span></span></span><span
                                    >Google Analytics Opt-out Browser Add-on Download Page</span></span></a></span></span></span>
                                </div>
                            </a>
                        </p>
                        <br/>
                        <p dir="auto">Finally, we may also share your Personal Information to comply with applicable
                            laws and regulations, to respond to a subpoena, search warrant or other lawful requests for
                            information we receive, or to otherwise protect our rights.</p><br/>
                        <p dir="auto"><strong>Behavioural advertising</strong><br/>As described above, we use your
                            Personal Information to provide you with targeted advertisements or marketing communications
                            we believe may be of interest to you. For more information about how targeted advertising
                            works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at <a
                                href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
                                target="_blank" rel="noreferrer ugc nofollow" >
                                <div ><span><span><span
                                    aria-expanded="false" aria-haspopup="true" data-testid="hover-card-trigger-wrapper"><a
                                    href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
                                    tabIndex="0" role="button" data-testid="inline-card-resolved-view"
                                    ><span data-testid="inline-card-icon-and-title"><span><span></span><img style={{maxWidth: "16px"}}
                                                                       src="https://thenai.org/wp-content/uploads/2021/07/cropped-nai_square-32x32.png"
                                                                       data-testid="inline-card-icon-and-title-image"/>
                                    </span><span>FAQ - NAI: Network Advertising Initiative</span></span></a></span></span></span>
                                </div>
                            </a>
                        </p>
                        <br/>
                        <p dir="auto">You can opt-out of targeted advertising by using the links below:</p>
                        <ul style={{padding: "20px"}}>
                            <li style={{display: "flex"}}>Facebook: <a href="https://www.facebook.com/settings/?tab=ads" target="_blank"
                                             rel="noreferrer ugc nofollow">https://www.facebook.com/settings/?tab=ads</a>
                            </li>
                            <li style={{display: "flex"}}>Google: <a href="https://www.google.com/settings/ads/anonymous" target="_blank"
                                           rel="noreferrer ugc nofollow">
                                <div><span><span><span
                                    aria-expanded="false" aria-haspopup="true" data-testid="hover-card-trigger-wrapper"><a
                                    href="https://www.google.com/settings/ads/anonymous" tabIndex="0" role="button"
                                    data-testid="inline-card-resolved-view"><span
                                    data-testid="inline-card-icon-and-title"><span><span></span>
                                    <img
                                    style={{maxWidth: "16px"}} src="https://www.google.com/favicon.ico"
                                    data-testid="inline-card-icon-and-title-image"/></span><span
                                    >Ads Settings</span></span></a></span></span></span>
                                </div>
                            </a></li>
                            <li style={{display: "flex"}}>Bing: <a
                                href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads"
                                target="_blank" rel="noreferrer ugc nofollow">
                                <div><span ><span><span
                                    aria-expanded="false" aria-haspopup="true" data-testid="hover-card-trigger-wrapper"><a
                                    href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads"
                                    tabIndex="0" role="button" data-testid="inline-card-resolved-view"
                                    ><span data-testid="inline-card-icon-and-title"><span><span
                                    ></span><img style={{maxWidth: "16px"}}
                                                                       src="https://c.s-microsoft.com/favicon.ico?v2"
                                                                       data-testid="inline-card-icon-and-title-image"/></span><span
                                    >Personalized ads - Microsoft Advertising</span></span></a></span></span></span>
                                </div>
                            </a></li>
                        </ul>
                        <p dir="auto">Additionally, you can opt-out of some of these services by visiting the Digital
                            Advertising Alliance’s opt-out portal at <a href="http://optout.aboutads.info/"
                                                                        target="_blank"
                                                                        rel="noreferrer ugc nofollow">http://optout.aboutads.info/</a>.
                        </p><br/>
                        <p dir="auto"><strong>Do not track</strong><br/>Please note that we do not alter our Site’s data
                            collection and use practices when we see a Do Not Track signal from your browser.</p><br/>
                        <p dir="auto"><strong>Your rights</strong><br/>If you are a European resident, you have the
                            right
                            to access personal information we hold about you and to ask that your personal information
                            be corrected, updated, or deleted. If you would like to exercise this right, please contact
                            us through the contact information below.</p>
                        <p dir="auto">Additionally, if you are a European resident we note that we are processing your
                            information in order to fulfill contracts we might have with you (for example if you make an
                            order through the Site), or otherwise to pursue our legitimate business interests listed
                            above. Additionally, please note that your information will be transferred outside of
                            Europe, including Canada and the United States.</p><br/>
                        <p dir="auto"><strong>Data retention</strong><br/>When you place an order through the Site, we
                            will maintain your Order Information for our records unless and until you ask us to delete
                            this information.</p><br/>
                        <p dir="auto"><strong>Changes</strong><br/>We may update this privacy policy from time to time
                            in
                            order to reflect, for example, changes to our practices or for other operational, legal, or
                            regulatory reasons.</p><br/>
                        <p dir="auto"><strong>Contact us</strong><br/>For more information about our privacy practices,
                            if you have questions, or if you would like to make a complaint, please contact us by e‑mail
                            at <a href="mailto:hello@puppypop.be">hello@puppypop.be</a> or by mail using the details
                            provided below:</p><br/><br/>
                        <p dir="auto">PUPPY POP<br/>voie de Liège 59, Embourg 4053, BELGIUM</p>
                    </div>
                </div>

            </div>
        </>
    );
};
