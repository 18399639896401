import "./OrderModal.css";
import React from "react";
import Moment from "moment/moment";
import {useOrders} from "../../../../../contexts/OrdersProvider";


export const OrderModal = () => {
    const {setIsOrderModalOpen, ordersForm} = useOrders();

    const {
        id,
        order_id,
        date_created,
        total,
        address,
        order_items,
        status
    } = ordersForm;

    const formattedDate = Moment(date_created).format("YYYY-MM-DD");
    const statusColor = status === "delivered" ? "green" : "orange";

    const {
        street,
        state,
        country,
        first_name,
        last_name,
        phone,
    } = address || {};

    return (
        <div className="order-modal-container">
            <div className="order-input-container">
                <div key={id} className="ordered-modal-items-card">
                    <div className="order-modal-id-container">
                        <span style={{float: "left"}}>{formattedDate}</span>
                        <span style={{color: statusColor, float: "right"}}>{status}</span>
                    </div>
                    <div className="payment_modal-id-container">
                        <span>Order ID: </span>
                        <span>{order_id}</span>
                    </div>
                    <div>
                        <span className="bold-container">Amount: </span>
                        <span>${total}</span>
                    </div>
                    <div className="price-container">
                        <span className="bold-container">Delivery-Address:</span>
                        <span>
                {street} {state}{" "} {country}
              </span>
                    </div>
                    <div className="price-container">
                        <span className="bold-container">Contact:</span>
                        <span>
                {first_name} {last_name}{" "}{phone}
              </span>
                    </div>
                    <div className="ordered-modal-items-card">
                        {order_items?.map(
                            ({id, product, total, qty}) => (
                                <div className="products-card" key={id}>
                                    <img src={`/api/uploaded/${product.img}`} alt={product.name}/>
                                    <div className="description">
                                        <span className="name">Name: {product.name}</span>
                                        <span className="name">Size: {product.size}</span>
                                        <span className="qty">Qty: {qty}</span>
                                        <span className="price">Price: ${total}</span>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
                <div className="btn-container">
                    <button className="cancel" onClick={() => setIsOrderModalOpen(false)}>Cancel</button>
                </div>
            </div>
        </div>
    );
};
