import {
    createContext,
    useContext,
    useEffect,
    useReducer,
    useState,
} from "react";

import {getAllCategories} from "../services/services";
import {getAllProducts} from "../services/services";
import {dataReducer, initialState} from "../reducer/dataReducer";

const DataContext = createContext();

export function DataProvider({children}) {
    const [state, dispatch] = useReducer(dataReducer, initialState);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const getAllSneakers = async () => {
        try {
            setError(false);
            setLoading(true);
            const response = await getAllProducts();
            dispatch({type: "GET_ALL_PRODUCTS_FROM_API", payload: [...response.data.products]});
        } catch (error) {
            setError(true);
            console.error(error)
        } finally {
            setLoading(false);
        }
    };

    const getCategories = async () => {
        try {
            const response = await getAllCategories();
            dispatch({type: "GET_ALL_CATEGORIES", payload: [...response.data.categories]});
        } catch (error) {
            console.error(error)
        }
    };

    useEffect(() => {
        setLoading(true);
        getAllSneakers();
        getCategories();
        setLoading(false);
    }, []);

    return (
        <DataContext.Provider
            value={{
                state,
                dispatch,
                loading,
                error,
                setLoading
            }}>
            {children}
        </DataContext.Provider>
    );
}

export const useData = () => useContext(DataContext);
