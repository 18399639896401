import React, {useState} from "react";
import "./ContactForm.css";
import {toast} from "react-hot-toast";
import {sendContactService} from "../../../../services/contact-services/sendContactService";

export const ContactForm = () => {
    const [contactForm, setContactForm] = useState({name: "", email: "", text: ""});

    const sendContactMessage = async (form) => {
        try {
            const response = await sendContactService(form.name, form.email, form.text);
            if (response.status === 200) {
                toast.success(`Message sent successfully!`);
            }
        } catch (error) {
            console.error(error);
            toast.error(`Message sent Error!`);
        }
    };

    return (
        <div className="contact-container">
            <div className="contact-input-container">
                <div className="contact-autor-container">
                    <p>
                        Thank you for being a part of this adventure with us. We will be happy to hear from you!
                        <br/><br/>
                        We typically respond within 24 hours. On holidays, our response time may be slightly longer,
                        as we take the time to carefully read each message from our customers.
                        <br/><br/>
                        hello@puppypop.be
                    </p>
                </div>

                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        sendContactMessage(contactForm);
                        setContactForm({name: "", email: "", text: ""});
                    }}
                    className="input-containter"
                >
                    <input
                        required
                        value={contactForm.name}
                        onChange={(e) =>
                            setContactForm({...contactForm, name: e.target.value})
                        }
                        type="text"
                        placeholder="Name"
                    />
                    <input
                        required
                        value={contactForm.email}
                        onChange={(e) =>
                            setContactForm({...contactForm, email: e.target.value})
                        }
                        type="email"
                        placeholder="Email"
                    />
                    <textarea
                        required
                        value={contactForm.text}
                        onChange={(e) =>
                            setContactForm({...contactForm, text: e.target.value})
                        }
                        rows="5"
                        minlength="3"
                        maxlength="500"
                        // className="contact-input-text"
                        placeholder="Message"
                    />

                    <div className="btn-container">
                        <input className="submit" type="submit" value="Send Message"/>
                    </div>
                </form>
            </div>
        </div>
    );
};
