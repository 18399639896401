import {createContext, useContext, useReducer, useState} from "react";
import {adminDataReducer, initialOrders} from "../reducer/adminDataReducer";
import {useAuth} from "./AuthProvider";
import {getAdminOrdersList} from "../services/admin-services/adminOrdersService";
import {useLocation, useNavigate} from "react-router-dom";
import toast from "react-hot-toast";

const OrdersContext = createContext();

export function OrdersProvider({children}) {

    const {auth, setAuth} = useAuth();

    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        localStorage.removeItem("isAuth");
        localStorage.removeItem("access_token");
        localStorage.removeItem("token_type");
        localStorage.removeItem("user_id");
        setAuth({token: "", isAuth: false});
        dispatch({type: "SET_CART", payload: []});
        dispatch({type: "SET_WISHLIST", payload: []});
    };


    const [loading, setLoading] = useState(false);
    const [ordersForm, setOrderForm] = useState({
        id: "",
        order_id: "",
        date_created: "",
        total: 0,
        address: "",
        order_items: [],
        status: "",
    });

    const [editProductIndex, setEditProductIndex] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
    const [ordersDataState, dispatch] = useReducer(adminDataReducer, initialOrders);

    const getAdminOrders = async () => {
        if (!auth.isAuth || !auth.isAdmin) return;
        try {
            setLoading(true);
            const response = await getAdminOrdersList(auth.token, auth.token_type);
            dispatch({type: "GET_ALL_ORDERS", payload: [...response.data.orders]});

        } catch (error) {
            if (error.response?.status === 401) {
                handleLogout();
                toast.error("Your session expired. Please login again!");
                navigate("/login", {state: {from: location}});
            }
            console.error(error.response);
        } finally {
            setLoading(false);
        }
    };

    return (
        <OrdersContext.Provider
            value={{
                auth,
                dispatch,
                loading,
                setLoading,
                editProductIndex,
                setEditProductIndex,
                ordersForm,
                setOrderForm,
                isOrderModalOpen,
                setIsOrderModalOpen,
                isEdit,
                setIsEdit,
                ordersDataState,
                getAdminOrders
            }}
        >
            {children}
        </OrdersContext.Provider>
    );
}

export const useOrders = () => useContext(OrdersContext);
