import "./ProductImage.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";


import React from "react";

export const ProductImage = ({selectedProduct}) => {

    const images = [
        {
            original: `/api/uploaded/${selectedProduct?.img}`,
            thumbnail: `/api/uploaded/${selectedProduct?.img}`,
        }
    ];

    return (
        <ImageGallery
            items={images.concat(selectedProduct.photos.map((a) => (
                {
                    original: `/api/uploaded/${a.img_url}`,
                    thumbnail: `/api/uploaded/${a.img_url}`
                }
            )))}
            thumbnailPosition="left"
            showPlayButton={false}
            slideDuration={800}
            originalHeight="500px"
            originalWidth="500px"
        />
    );
};
