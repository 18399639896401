import axios from "axios";

export const getPaymentPage = async (cartItems, country_code) => {
    return await axios.post("/api/billing/create_checkout_session",
        {cart : cartItems, country_code: country_code},
        {}
    );
};

export const getSuccessPayment = async (sessionId) => {
    return await axios.get(`/api/billing/success/${sessionId}`,{});
};