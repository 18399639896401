// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-container {
  padding: 25px;
}

.profile-container > h3 {
  margin-bottom: 20px;
  text-align: center;
}

.profile-details {
  margin-bottom: 20px;
}

.profile-details > * {
  margin-bottom: 10px;
}

.profile-details > * > :first-child {
  font-weight: 600;
}
.profile-details .name {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.profile-details .email {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/pages/UserProfile/Profile/Profile.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,qBAAqB;EACrB,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,qBAAqB;EACrB,8BAA8B;AAChC","sourcesContent":[".profile-container {\n  padding: 25px;\n}\n\n.profile-container > h3 {\n  margin-bottom: 20px;\n  text-align: center;\n}\n\n.profile-details {\n  margin-bottom: 20px;\n}\n\n.profile-details > * {\n  margin-bottom: 10px;\n}\n\n.profile-details > * > :first-child {\n  font-weight: 600;\n}\n.profile-details .name {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n}\n.profile-details .email {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
