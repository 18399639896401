import React from "react";
import "./WhoIsSection.css";
import {useUserData} from "../../../../contexts/UserDataProvider";

export const WhoIsSection = () => {
    const {refPage} = useUserData();
    return (
        <>
            <div ref={refPage} className="who-heading-container">
                <br></br>
                <div className="who-text-container">
                    <img alt="author" src="/api/uploaded/author.jpg" className="who-img"/>
                    <h3 className="who-subtitle">
                        Hi, I'm Oléna, proudly caring for Misty, my beloved Podenco Canario sighthound whom I adopted in
                        2022.
                        <br></br>
                        <br></br>
                        Thanks to Misty's inspiration, I've crafted a collection of comfy, sporty, and practical
                        clothing tailored for greyhounds. I'm passionate about colors that shift with the seasons and my
                        mood. If your active pup needs protection from the cold and rain, you can grab similar outfits
                        here for your furry friend.
                        <br></br>
                        <br></br>
                        Welcome to Puppy pop Tale — your must-visit e-shop in Belgium for handmade, custom-designed
                        clothes and accessories for sighthounds like Iggy, Whippet, Podenco, Saluki, Galgo, Greyhound &
                        Italian Greyhound. We deliver worldwide.
                        <br></br>
                        <br></br>
                        Warmest regards,
                        <br></br>
                        Oléna & Misty
                    </h3>
                </div>
            </div>
        </>
    );
};
