import "./AdminCategoriesSection.css";
import React from "react";
import {MdDelete} from "react-icons/md";
import {AiOutlineEdit} from "react-icons/ai";
import {RiAddFill} from "react-icons/ri";
import {useCategories} from "../../../../contexts/CategoriesProvider";
import {CategoryModal} from "./components/CategoryModal/CategoryModal";

export const AdminCategoriesSection = () => {
    const {
        setCategoryForm,
        isCategoryModalOpen,
        setIsCategoryModalOpen,
        setIsEdit,
        categoriesDataState,
        deleteCategory,
        getAdminCategoriesHandler
    } = useCategories();

    const addProductHandler = () => {
        setIsCategoryModalOpen(true);
    };

    const editCategoryHandler = (category) => {
        setIsCategoryModalOpen(true);
        setCategoryForm(category);
        setIsEdit(true);
    };

    const deleteCategoryHandler = (category) => {
        deleteCategory(category);
        getAdminCategoriesHandler();
    };

    return (
        <div>
            <button className="create-btn" onClick={addProductHandler}>
                <RiAddFill className="plus"/>
                New Category
            </button>
            <div className="admin-menu-categories-container">
                {categoriesDataState.categories.map((category) => (
                    <div className="admin-menu-category-card" key={category.id}>
                        <div className="category-description">
                            <h3>{category.categoryName}</h3>
                        </div>
                        <div className="button-section">
                                <MdDelete
                                    style={{color: "red"}}
                                    size={25}
                                    onClick={() => deleteCategoryHandler(category)}
                                />
                                <AiOutlineEdit
                                    size={25}
                                    onClick={() => editCategoryHandler(category)}
                                />
                        </div>
                    </div>
                ))}

            </div>
            {isCategoryModalOpen && <CategoryModal/>}
        </div>
    );
};
