import {createRoot} from 'react-dom/client';
import "./index.css";
import App from "./App";
import {BrowserRouter as Router} from "react-router-dom";
import {DataProvider} from "./contexts/DataProvider";
import {AuthProvider} from "./contexts/AuthProvider";
import {UserProvider} from "./contexts/UserDataProvider";
import {AddressProvider} from "./contexts/AddressProvider";
import {ProductsProvider} from "./contexts/ProductsProvider";
import {CategoriesProvider} from "./contexts/CategoriesProvider";
import {OrdersProvider} from "./contexts/OrdersProvider";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <Router>
        <AuthProvider>
            <DataProvider>
                <UserProvider>
                    <ProductsProvider>
                        <OrdersProvider>
                            <CategoriesProvider>
                                <AddressProvider>
                                    <App/>
                                </AddressProvider>
                            </CategoriesProvider>
                        </OrdersProvider>
                    </ProductsProvider>
                </UserProvider>
            </DataProvider>
        </AuthProvider>
    </Router>
);
