// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1 {
  font-size: 2rem;
  /*line-height: 1.3;*/
}

h2 {
  font-size: 1.5rem;
  /*line-height: 1.4;*/
}

@media (min-width: 840px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.75rem;
  }
}

@media (min-width: 1024px) {
  h2 {
    font-size: 2rem;
  }
}
button {
  cursor: pointer;
}

:root {
  --primary-color: rgb(66, 66, 66);
  --primary-color-onhover: black;

  --secondary-background-color: rgb(232, 232, 232);
  --secondary-background-color-onhover: rgb(214, 214, 214);
  --primary-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF;AACA;EACE,eAAe;AACjB;;AAEA;EACE,gCAAgC;EAChC,8BAA8B;;EAE9B,gDAAgD;EAChD,wDAAwD;EACxD;;cAEY;AACd","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\nh1 {\n  font-size: 2rem;\n  /*line-height: 1.3;*/\n}\n\nh2 {\n  font-size: 1.5rem;\n  /*line-height: 1.4;*/\n}\n\n@media (min-width: 840px) {\n  h1 {\n    font-size: 2.5rem;\n  }\n\n  h2 {\n    font-size: 1.75rem;\n  }\n}\n\n@media (min-width: 1024px) {\n  h2 {\n    font-size: 2rem;\n  }\n}\nbutton {\n  cursor: pointer;\n}\n\n:root {\n  --primary-color: rgb(66, 66, 66);\n  --primary-color-onhover: black;\n\n  --secondary-background-color: rgb(232, 232, 232);\n  --secondary-background-color-onhover: rgb(214, 214, 214);\n  --primary-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
