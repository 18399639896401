import "./ProductListingSection.css";
import {BsFillStarFill} from "react-icons/bs";
import React from "react";
import {useNavigate} from "react-router-dom";

import {useData} from "../../../../contexts/DataProvider";
import {Link} from "react-router-dom";
import {getCategoryWiseProducts} from "../../../../helpers/filter-functions/category";
import {getRatedProducts} from "../../../../helpers/filter-functions/ratings";
import {getPricedProducts} from "../../../../helpers/filter-functions/price";
import {getSortedProducts} from "../../../../helpers/filter-functions/sort";
import {getSearchedProducts} from "../../../../helpers/searchedProducts";

export const ProductListingSection = () => {
    const {state} = useData();
    const navigate = useNavigate();

    const {
        allProductsFromApi,
        inputSearch,
        filters: {rating, categories, price, sort},
    } = state;

    const searchedProducts = getSearchedProducts(allProductsFromApi, inputSearch);

    const ratedProducts = getRatedProducts(searchedProducts, rating);

    const categoryProducts = getCategoryWiseProducts(ratedProducts, categories);

    const pricedProducts = getPricedProducts(categoryProducts, price);

    const sortedProducts = getSortedProducts(pricedProducts, sort || 'newest');

    const priceDiv = (original_price, discounted_price) => {
        if (!discounted_price || discounted_price === 0) {
            return <div className="price-container">
                <p className="discount-price">from € {original_price}</p>
            </div>
        }

        return <div className="price-container">
            <p className="original-price">€ {original_price}</p>
            <p className="discount-price">€ {discounted_price}</p>
        </div>
    }

    const openProductPage = (product) => {
        navigate(`/product-details/${product.id}`);
    }

    const stars = new Array(5).fill(null);

    return (
        <div className="product-card-container">
            {!sortedProducts.length ? (
                <h2 className="no-products-found">
                    Sorry, there are no matching products!
                </h2>
            ) : (
                sortedProducts.map((product) => {
                    const {
                        id,
                        name,
                        original_price,
                        discounted_price,
                        img,
                    } = product;

                    return (

                        <div className="product-card" key={id}>
                            <Link to={`/product-details/${id}`}>
                                <div className="product-card-image">
                                    <img alt={name} src={`/api/uploaded/${img}`}/>
                                </div>
                            </Link>
                            <div className="product-card-details">
                                <div className="trending-name" onClick={() => openProductPage(product)}>
                                    <h3>{name}</h3>
                                </div>

                                <div className="ratings-reviews">
                                    <span></span>
                                    {stars.map((_, index) => (
                                        <BsFillStarFill color={"orange"} key={index} />
                                    ))}
                                    
                                </div>
                                {priceDiv(original_price, discounted_price)}
                            </div>
                        </div>
                    );
                })
            )}
        </div>
    );
};
