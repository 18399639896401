import React from "react";

import "./Admin.css";
import {AdminMenu} from "./components/AdminMenu/AdminMenu";
import {Outlet} from "react-router-dom";

export const Admin = () => {
    return (
        <div className="page-container">
            <AdminMenu className="admin_menu"/>
            <Outlet/>
        </div>
    );
};