import React from "react";

import "./ProductListing.css";
import {Filter} from "./components/Filter/Filter";
import {ProductListingSection} from "./components/ProductListingSection/ProductListingSection";
import {useData} from "../../contexts/DataProvider";
import {Footer} from "../../components/Footer/Footer";

export const ProductListing = () => {
    const {loading} = useData();
    return (
        !loading && (
            <>
                <Filter className="filters"/>
                <div className="page-container">
                    <ProductListingSection className="products-container"/>
                </div>
                <Footer/>
            </>
        )
    );
};
