import {createContext, useContext, useState} from "react";
import {toast} from "react-hot-toast";
import {removeAddressService} from "../services/address-services/removeAddressService";
import {useAuth} from "./AuthProvider";
import {useUserData} from "./UserDataProvider";

const AddressContext = createContext();

export function AddressProvider({children}) {
    const {dispatch} = useUserData();
    const [addressForm, setAddressForm] = useState({
        id: "",
        street: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        phone: "",
        name: "",
        first_name: "",
        last_name: "",
    });
    const {auth} = useAuth();

    const [editAddressIndex, setEditAddressIndex] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

    const deleteAddress = async (address) => {
        try {
            const response = await removeAddressService(address.id, auth.token, auth.token_type);
            toast.success(`address deleted successfully!`);
            dispatch({type: "SET_ADDRESS", payload: response.data.addresses});
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <AddressContext.Provider
            value={{
                editAddressIndex,
                setEditAddressIndex,
                addressForm,
                setAddressForm,
                isAddressModalOpen,
                setIsAddressModalOpen,
                isEdit,
                setIsEdit,
                deleteAddress,
            }}
        >
            {children}
        </AddressContext.Provider>
    );
}

export const useAddress = () => useContext(AddressContext);
