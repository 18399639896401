import React from "react";
import "./Footer.css";
import {Link, NavLink} from "react-router-dom";
import {SiFacebook, SiInstagram, SiPinterest} from "react-icons/si";

export const Footer = () => {
    const copyrightYear = new Date().getFullYear();
    return (

        <div className="footer">
            <div className="social-links">
                <Link className="insta_icon" to="https://www.instagram.com/puppypopbe/" target="_blank">
                    <SiInstagram size={30}/>
                </Link>
                <Link className="insta_icon" to="https://www.facebook.com/puppypopbe/" target="_blank">
                    <SiFacebook size={30}/>
                </Link>
                <Link className="insta_icon" to="https://www.pinterest.com/0w567unx0hjp5bbbok38kc5ifxqlti/" target="_blank">
                    <SiPinterest size={30}/>
                </Link>
            </div>
            <nav className="footer-nav">
                <NavLink to="/policies/shipping-policy" className="menu_item">
                    Shipping
                </NavLink>
                <NavLink to="/policies/refund-policy" className="menu_item">
                    Returns &amp; Exchanges
                </NavLink>
                <NavLink to="/policies/privacy-policy" className="menu_item">
                    Privacy Policy
                </NavLink>
                <NavLink to="/policies/terms-of-service" className="menu_item">
                    Terms of Service
                </NavLink>
                <NavLink to="/contacts" className="menu_item">
                    Contact Us
                </NavLink>
            </nav>
            <small> &copy; {copyrightYear} puppy pop. All rights reserved.</small>
        </div>
    );
};
