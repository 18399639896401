import React, {createContext, useContext, useState} from "react";
import {toast} from "react-hot-toast";
import {useLocation, useNavigate} from "react-router-dom";
import {loginService} from "../services/auth-services/loginService";

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [currentPage, setCurrentPage] = useState("profile");
    const [error, setError] = useState("");
    const [loginLoading, setLoginLoading] = useState(false);
    const access_token = localStorage.getItem("access_token");
    const token_type = localStorage.getItem("token_type");
    const user_id = localStorage.getItem("user_id");
    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");
    const email = localStorage.getItem("email");
    const is_admin = localStorage.getItem("is_admin");
    const navigate = useNavigate();
    const location = useLocation();

    const [auth, setAuth] = useState(
        access_token
            ? {token: access_token, isAuth: true, isAdmin: is_admin, token_type, user_id, first_name, last_name, email}
            : {token: "", isAuth: false, isAdmin: false}
    );

    const [loginCredential, setLoginCredential] = useState({
        email: "",
        password: "",
    });

    const loginHandler = async (e) => {
        e.preventDefault();
        try {
            setLoginLoading(true);
            setError("");

            const response = await loginService(loginCredential.email, loginCredential.password);
            toast.success(`Welcome back! ${response.data.first_name} ${response.data.last_name}`);
            const access_token = response.data.access_token;
            const token_type = response.data.token_type;
            const user_id = response.data.user_id;
            const first_name = response.data.first_name;
            const last_name = response.data.last_name;
            const email = response.data.email;
            const is_admin = response.data.is_admin;

            setAuth({
                token: access_token,
                isAuth: true,
                isAdmin: is_admin,
                token_type,
                user_id,
                first_name,
                last_name,
                email
            });

            localStorage.setItem("access_token", access_token);
            localStorage.setItem("isAuth", true);
            localStorage.setItem("token_type", token_type);
            localStorage.setItem("user_id", user_id);
            localStorage.setItem("first_name", first_name);
            localStorage.setItem("last_name", last_name);
            localStorage.setItem("email", email);
            localStorage.setItem("is_admin", is_admin);
            setLoginCredential({email: "", password: ""});
            navigate(location?.state?.from.pathname || "/");

        } catch (error) {
            let error_msg = ""
            if (Array.isArray(error.response?.data?.detail)) {
                error.response?.data?.detail.forEach((err) => {
                        toast.error(err.msg);
                        error_msg += err.msg + " ";
                    }
                );
                setError(error_msg);
            } else {
                console.error("loginHandler NOT Array", error.response);
                toast.error(error.response?.data?.detail?.error);
                setError(error.response?.data?.detail?.error);
            }

        } finally {
            setLoginLoading(false);
        }
    };

    const logoutHandler = () => {
        localStorage.removeItem("isAuth");
        localStorage.removeItem("access_token");
        localStorage.removeItem("token_type");
        localStorage.removeItem("user_id");
        setAuth({token: "", isAuth: false});
        navigate("/login");
    };

    return (
        <AuthContext.Provider
            value={{
                auth,
                setAuth,
                loginHandler,
                error,
                setError,
                loginLoading,
                setLoginLoading,
                loginCredential,
                setLoginCredential,
                setCurrentPage,
                currentPage,
                logoutHandler
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
