import React from "react";
import {useUserData} from "../../contexts/UserDataProvider";
import {BsFillStarFill} from "react-icons/bs";
import "./Wishlist.css";
import {useNavigate} from "react-router-dom";
import {useData} from "../../contexts/DataProvider";
import {SizeModal} from "../ProductListing/components/SizeModal/SizeModal";

export const Wishlist = () => {
    const navigate = useNavigate();

    const {
        userDataState,
        addToCartHandler,
        removeFromWishlistHandler,
    } = useUserData();

    const {loading} = useData();


    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [activeProduct, setActiveProduct] = React.useState({});

    const openSizeModal = (product) => {
        setActiveProduct(product);
        setIsModalOpen(true);
    }

    return (
        !loading &&
        (userDataState.wishlistProducts.length ? (
            <div>
                <h1 className="page-heading">Wishlist</h1>
                <div className="wishlist-products-container">
                    {userDataState.wishlistProducts?.map((product) => (
                        <div className="wishlist-card" key={product.id}>
                            <div>
                                <img
                                    className="img-container"
                                    alt={product.name}
                                    src={`/api/uploaded/${product.img}`}
                                />
                            </div>

                            <div className="product-card-details">
                                <h3>{product.name}</h3>
                                <p className="ratings">
                                    {product.rating}
                                    <BsFillStarFill color="orange"/> ({product.reviews} reviews){" "}
                                </p>
                                {product.discounted_price && product.discounted_price !== 0 ?
                                    <div className="price-container">
                                        <p className="original-price">€ {product.original_price}</p>
                                        <p className="discount-price">€ {product.discounted_price}</p>
                                    </div> :
                                    <div className="price-container">
                                        <p className="discount-price">€ {product.original_price}</p>
                                    </div>
                                }
                                <p>Category: {product.category_name}</p>
                                <div className="info">
                                    {!product.is_stock && (
                                        <p className="out-of-stock">Out of stock</p>
                                    )}
                                    {product.trending && <p className="trending">Trending</p>}
                                </div>
                            </div>

                            <div className="wishlist-btn-container">
                                <button
                                    className="cart-wishlist-btn"
                                    onClick={() => openSizeModal(product)}
                                >
                                    Add to Cart
                                </button>
                                <button
                                    className="remove-from-wishlist-btn"
                                    onClick={() => removeFromWishlistHandler(product)}
                                >
                                    Remove from Wishlist
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            {isModalOpen && <SizeModal
                product={activeProduct}
                handleAddToCart={addToCartHandler}
                setIsModalOpen={setIsModalOpen}
            />}
            </div>
        ) : (
            <div className="wishlist-empty-container">
                <h2 className="page-heading">Wishlist is Empty</h2>
                <button onClick={() => navigate("/product-listing")}>Explore</button>
            </div>
        ))
    );
};
