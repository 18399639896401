export const getSortedProducts = (products, sort) => {

    if (sort === "newest") {
        return products.sort((a, b) => b.date_created - a.date_created);
    } else if (sort === "oldest") {
        return products.sort((a, b) => a.date_created - b.date_created);
    } else if (sort === "lowToHigh") {
        return products.sort((a, b) => a.original_price - b.original_price);
    } else if (sort === "highToLow") {
        return products.sort((a, b) => b.original_price - a.original_price);
    }
    return products;
}