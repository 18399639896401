import React from "react";
import "../../PolicySection.css";


export const RefundSection = () => {
    return (
        <>
            <div className="hero-heading-container">
                <h1 className="hero-title">Refund policy</h1>
                <br></br>
                <div className="hero-subtitle">
                    <p>&nbsp;</p>
                    <p><strong>No return no refund policy</strong><br/>

                        All our products are made to order, therefore they are unfortunately not eligible for any
                        exchanges or returns and sales are final.<br/><br/>


                        <strong>Damages and issues</strong>
                        <br/>
                        In case, you find any defective, damaged or if you receive the wrong item, please contact us at
                        hello@puppypop.be so that we can evaluate the issue.</p><br/>

                    <p><br/></p>


                </div>

            </div>
        </>
    );
};
