import axios from "axios";

export const getAdminOrdersList = async (access_token, token_type) => {
    return await axios.get("/api/admin/orders", {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `${token_type} ${access_token}`,
            },
        },
    );
};
