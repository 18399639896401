// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address-section-container {
  padding: 20px;
}

.add-address-btn-container button {
  padding: 10px 30px;
  color: white;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  margin-bottom: 15px;
}

.add-address-btn-container button:hover {
  cursor: pointer;
  background-color: black;
}
.add-address-btn-container .plus {
  margin-right: 5px;
}

.address-card .name {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: larger;
}
.profile-address-container {
  display: flex;
  flex-flow: column nowrap;
}
.address-card {
  display: flex;
  flex-direction: column;
}

.address-card .address {
  margin-bottom: 5px;
}
.add-address-btn-container button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.address-card span {
  font-weight: 600;
}

.address-card .phone {
  margin-bottom: 10px;
}

.address-btn-container > button {
  margin-right: 15px;
  padding: 6px 18px;
  border: none;
  border-radius: 5px;
}

.address-btn-container > button:hover{
  cursor: pointer;
  background-color: var(--secondary-background-color-onhover);
}

.address-section-container .address-card:not(:first-child) {
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/UserProfile/Addresses/Addresses.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,sCAAsC;EACtC,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,uBAAuB;AACzB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,2DAA2D;AAC7D;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".address-section-container {\n  padding: 20px;\n}\n\n.add-address-btn-container button {\n  padding: 10px 30px;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  background-color: var(--primary-color);\n  margin-bottom: 15px;\n}\n\n.add-address-btn-container button:hover {\n  cursor: pointer;\n  background-color: black;\n}\n.add-address-btn-container .plus {\n  margin-right: 5px;\n}\n\n.address-card .name {\n  font-weight: 600;\n  margin-bottom: 10px;\n  font-size: larger;\n}\n.profile-address-container {\n  display: flex;\n  flex-flow: column nowrap;\n}\n.address-card {\n  display: flex;\n  flex-direction: column;\n}\n\n.address-card .address {\n  margin-bottom: 5px;\n}\n.add-address-btn-container button {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.address-card span {\n  font-weight: 600;\n}\n\n.address-card .phone {\n  margin-bottom: 10px;\n}\n\n.address-btn-container > button {\n  margin-right: 15px;\n  padding: 6px 18px;\n  border: none;\n  border-radius: 5px;\n}\n\n.address-btn-container > button:hover{\n  cursor: pointer;\n  background-color: var(--secondary-background-color-onhover);\n}\n\n.address-section-container .address-card:not(:first-child) {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
