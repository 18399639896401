import React from "react";
import "../../PolicySection.css";


export const SizesSection = () => {
    return (
        <>
            <div className="hero-heading-container">
                <br></br>
                <br></br>
                <img className="size-img" alt="puppy_pop_size_guide" src={`/api/uploaded/size_chart.jpg`}/>
                <br></br>
                <br></br>
                <img className="size-img" alt="puppy_pop_size_measure" src={`/api/uploaded/measure_my_dog.jpg`}/>
                <br></br>
                <br></br>
            </div>
        </>
    );
};
