import "./ProductDescription.css";
import React from "react";
import {BsFillStarFill, BsTruck} from "react-icons/bs";
import {useUserData} from "../../../../contexts/UserDataProvider";
import {Link} from "react-router-dom";
import SizeModal from "../ProductModal/SizeModal";
import parse from 'html-react-parser'

export const ProductDescription = ({selectedProduct}) => {

    const {
        addToCartHandler,
        wishlistHandler,
        isProductInWishlist,
        cartLoading
    } = useUserData();


    const [currentProduct, setCurrentProduct] = React.useState(selectedProduct || {});
    const [showDescription, setShowDescription] = React.useState({materials: false, care: false, shipping: false});
    const [currentSize, setCurrentSize] = React.useState(selectedProduct?.sizes[0].size || 1);
    const [showSizeModal, setShowSizeModal] = React.useState(false);
    const [showMeasureModal, setShowMeasureModal] = React.useState(false);
    const [showMore, setShowMore] = React.useState(false);

    const htmlDescription = parse(currentProduct?.description);

    const priceDiv = () => (currentProduct?.discounted_price || currentProduct?.discounted_price !== 0) ?
        <div className="product-price-container">
        <span className="product-original-price">
          €{currentProduct?.original_price}{" "}
        </span>
            <span className="product-discount-price">
          {" "}
                €{currentProduct?.discounted_price}
        </span>
        </div> :
        <div className="product-price-container">
            <span className="product-discount-price">
            €{currentProduct?.original_price}{" "}
            </span>
        </div>

    const handleSizeChange = (size_value, original_price, discounted_price) => {
        setCurrentSize(size_value);
        setCurrentProduct({
            ...currentProduct,
            size: size_value,
            original_price: original_price,
            discounted_price: discounted_price,
        });
    }

    const sizeButtons = () => currentProduct.sizes.map(({size, original_price, discounted_price}) => <button
        onClick={() => handleSizeChange(size, original_price, discounted_price)}
        className={(currentSize === size) ? "size-btn-selected" : "size-btn"}
    >
        {size}
    </button>)

    return (
        <div className="product-details-description">
            <h1 className="product-name">{selectedProduct?.name}</h1>
            <p className="product-tax">Tax and <a href="/policies/shipping-policy">Shipping</a> calculated at
                checkout.</p>
            <div className="ratings-reviews">
                <span></span>
                <span>{selectedProduct?.rating}</span>{" "}
                <BsFillStarFill color={"orange"}/>
                <span>
                    <span className="review">({selectedProduct?.reviews}) reviews </span>
                </span>
                {selectedProduct?.trending && (
                    <span className="trending">
            {selectedProduct?.trending ? "Trending" : ""}
                </span>
                )}
            </div>
            {priceDiv()}
            <div className={`description-container-${showMore}`}>
                <span>Description</span>: <br/>
                {htmlDescription}
            </div>
            <span className="gender-container-description"
                  onClick={() => setShowMore(!showMore)}>
                {showMore ? "  << less" : "Show more >>"}
            </span>
            <span className="gender-container size-link">
                <span onClick={() => setShowSizeModal(true)}>Show size chart</span>
              </span>
            <span className="gender-container size-link">
                <span onClick={() => setShowMeasureModal(true)}>Show measure</span>
              </span>
            <span className="gender-container">
                <span>Category</span>: {selectedProduct?.category_name}
              </span>
            <p className="size-container">
                <span>Size</span>:
            </p>
            <div className="size-buttons-container">
                {sizeButtons().map((e) => e)}
            </div>
            <div className="tags">
                {!selectedProduct?.is_stock && (
                    <span className="out-of-stock">
            {selectedProduct?.is_stock ? "In Stock" : "Out of stock"}
          </span>
                )}
            </div>
            <div className="product-card-buttons-container">
                <button disabled={cartLoading}
                        onClick={() => addToCartHandler(currentProduct)}
                        className="add-to-cart-btn"
                >
                    Add to cart
                </button>
                <button disabled={cartLoading}
                        onClick={() => wishlistHandler(currentProduct)}
                        className="add-to-wishlist-btn"
                >
                    {!isProductInWishlist(selectedProduct)
                        ? "Add to wishlist"
                        : "Remove from wishlist"}
                </button>
            </div>
            <div>
            </div>
            <span className="truck-container" onClick={() => setShowDescription({
                ...showDescription,
                materials: !showDescription.materials
            })}>
                <img alt="puppypop_material" src="/assets/icons/shirt.svg"></img>
                <span>Materials</span>
              </span>
            {showDescription.materials && (
                <div className="shipping-container">
                    <p>
                        {(selectedProduct?.materials) ? parse(selectedProduct?.materials) : "No materials."}
                    </p>
                </div>)
            }
            <hr className="separate-line"/>
            <span className="truck-container" onClick={() => setShowDescription({
                ...showDescription,
                care: !showDescription.care
            })}>
                <img alt="puppypop_care" src="/assets/icons/care.svg"></img>
                <span>Care</span>
              </span>
            {showDescription.care && (
                <div className="shipping-container">
                    <p>
                        {(selectedProduct?.care) ? parse(selectedProduct?.care) : "No care instructions."}
                    </p>
                </div>)
            }
            <hr className="separate-line"/>
            <span className="truck-container" onClick={() => setShowDescription({
                ...showDescription,
                shipping: !showDescription.shipping
            })}>
                <BsTruck size={25} className="truck"/>
                <span>Delivery</span>
              </span>
            {showDescription.shipping && (
                <div className="shipping-container">
                    <p>
                        <span>Shipping :</span>
                        <Link style={{color: "grey"}} to="/policies/shipping-policy">
                            Click here to learn more about shipping.
                        </Link>
                    </p>
                </div>)
            }

            <div className="bottom-margin"></div>
            {showSizeModal && (
                SizeModal("size_chart.jpg", setShowSizeModal)
            )}

            {showMeasureModal && (
                SizeModal("measure_my_dog.jpg", setShowMeasureModal)
            )}
        </div>
    );
};
