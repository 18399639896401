import React from "react";
import {Home} from "../pages/Home/Home";
import {Cart} from "../pages/Cart/Cart";
import {Route, Routes} from "react-router-dom";
import {Login} from "../pages/auth/Login/Login";
import {ProductListing} from "../pages/ProductListing/ProductListing";
import {ProductDetails} from "../pages/ProductDetails/ProductDetails";
import {RequiresAuth} from "../components/requires-auth/RequiresAuth";
import {Signup} from "../pages/auth/Signup/Signup";
import {Logout} from "../pages/auth/Logout/Logout";
import {Wishlist} from "../pages/Wishlist/Wishlist";
import {UserProfile} from "../pages/UserProfile/UserProfile";
import {Profile} from "../pages/UserProfile/Profile/Profile";
import {Addresses} from "../pages/UserProfile/Addresses/Addresses";
import {Orders} from "../pages/UserProfile/Orders/Orders";
import {PageNotFound} from "../pages/PageNotFound/PageNotFound";
import {Contact} from "../pages/Contact/Contact";
import {Privacy, Refund, Shipping, Sizes, Terms} from "../pages/Policies/Privacy";
import {Admin} from "../pages/Admin/Admin";
import {AdminProductSection} from "../pages/Admin/components/Products/AdminProductSection";
import {AdminCategoriesSection} from "../pages/Admin/components/Categories/AdminCategoriesSection";
import {AdminOrdersSection} from "../pages/Admin/components/Orders/AdminOrdersSection";
import {AdminClientsSection} from "../pages/Admin/components/Clients/AdminClientsSection";
import {Payment} from "../pages/Payment/Payment";

export const NavRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/cart" element={<Cart/>}/>
            <Route path="/wishlist" element={<Wishlist/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/logout" element={<Logout/>}/>
            <Route path="/contacts" element={<Contact/>}/>
            <Route path="/signup" element={<Signup/>}/>
            <Route path="/sizes" element={<Sizes/>}/>

            <Route path="/policies/privacy-policy" element={<Privacy/>}/>
            <Route path="/policies/terms-of-service" element={<Terms/>}/>
            <Route path="/policies/refund-policy" element={<Refund/>}/>
            <Route path="/policies/shipping-policy" element={<Shipping/>}/>

            <Route path="/product-listing" element={<ProductListing/>}/>
            <Route path="/product-details/:productId" element={<ProductDetails/>}/>
            <Route path="/billing/success/:sessionId" element={<Payment/>}/>

            <Route path="/profile" element={<UserProfile/>}>
                <Route
                    path="/profile/"
                    element={
                        <RequiresAuth>
                            <Profile/>
                        </RequiresAuth>
                    }
                />
                <Route path="/profile/orders" element={
                    <RequiresAuth>
                        <Orders/>
                    </RequiresAuth>
                }/>
                <Route path="/profile/addresses" element={
                    <RequiresAuth>
                        <Addresses/>
                    </RequiresAuth>
                }/>
            </Route>
            <Route path="/admin" element={<Admin/>}>
                <Route path="/admin/" element={
                    <RequiresAuth>
                        <AdminProductSection/>
                    </RequiresAuth>
                }/>
                <Route path="/admin/categories" element={<AdminCategoriesSection/>}/>
                <Route path="/admin/orders" element={<AdminOrdersSection/>}/>
                <Route path="/admin/clients" element={<AdminClientsSection/>}/>
            </Route>
            <Route path="*" element={<PageNotFound/>}/>
        </Routes>
    );
};
