// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    color: black;
    text-align: center;
    /*display: block;*/
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    padding: 0.8rem;
    justify-content: space-between;
    /*padding: 100px 30px;*/
    position: relative;
    z-index: 1;
    margin-top: 100px;
}

.footer > * {
    margin: 0.5rem;
}

.footer-nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    flex-wrap: wrap;
}


.footer > :first-child {
    font-size: 1rem;
}

.social-links {
    display: flex;
    flex-grow: 1;
    justify-content: center;
}

.social-links > * {
    padding: 0 1rem;
}

.social-links > *:hover {
    color: white;
}

.insta_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);
}

.insta_icon > :hover {
    color: #f29400;
}

@media (min-width: 768px) {
    .footer {
        padding: 1.2rem;
    }

    .footer small {
        font-size: 1rem;
    }

    .footer a {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .footer-nav {
        gap: 20px;
        margin-top: 15px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,uBAAuB;IACvB,aAAa;IACb,wBAAwB;IACxB,eAAe;IACf,8BAA8B;IAC9B,uBAAuB;IACvB,kBAAkB;IAClB,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,kBAAkB;IAClB,eAAe;AACnB;;;AAGA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,SAAS;QACT,gBAAgB;IACpB;AACJ","sourcesContent":[".footer {\n    color: black;\n    text-align: center;\n    /*display: block;*/\n    background-color: white;\n    display: flex;\n    flex-flow: column nowrap;\n    padding: 0.8rem;\n    justify-content: space-between;\n    /*padding: 100px 30px;*/\n    position: relative;\n    z-index: 1;\n    margin-top: 100px;\n}\n\n.footer > * {\n    margin: 0.5rem;\n}\n\n.footer-nav {\n    display: flex;\n    align-items: center;\n    justify-content: space-evenly;\n    text-align: center;\n    flex-wrap: wrap;\n}\n\n\n.footer > :first-child {\n    font-size: 1rem;\n}\n\n.social-links {\n    display: flex;\n    flex-grow: 1;\n    justify-content: center;\n}\n\n.social-links > * {\n    padding: 0 1rem;\n}\n\n.social-links > *:hover {\n    color: white;\n}\n\n.insta_icon {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: var(--primary-color);\n}\n\n.insta_icon > :hover {\n    color: #f29400;\n}\n\n@media (min-width: 768px) {\n    .footer {\n        padding: 1.2rem;\n    }\n\n    .footer small {\n        font-size: 1rem;\n    }\n\n    .footer a {\n        font-size: 1rem;\n    }\n}\n\n@media (max-width: 768px) {\n    .footer-nav {\n        gap: 20px;\n        margin-top: 15px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
