import React from "react";
import {MdClose} from "react-icons/md";
import "./SizeModal.css";

const SizeModal = (img, closeButton) => {
    return (
        <div className="size-modal-container">
            <div className="size-input-container">
                <div className="size-secondary-btn-section">
                    <MdClose
                        size={25}
                        onClick={() => closeButton(false)}
                    />
                </div>
                <img alt="puppy_pop_size" src={`/api/uploaded/${img}`}/>
            </div>
        </div>
    );
};


export default SizeModal;
