// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders-container {
  padding: 30px;
}

.ordered-items-card {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 30px;
  border-bottom: 1px solid rgb(231, 231, 231);
}
.ordered-items-card > * {
  margin-bottom: 6px;
}

.ordered-items-card .order-id-container {
  font-weight: 600;
}

.ordered-items-card .payment-id-container {
  font-size: small;
}
.payment-id-container {
  color: grey;
}

.orders-container .price-container {
  color: grey;
  font-size: small;
}

.orders-container .products-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
}

.ordered-items-card img {
  width: 90px;
}

.ordered-items-card .products-card .description {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.ordered-items-card .products-card .description > * {
  margin-bottom: 5px;
}
.ordered-items-card .products-card .name {
  color: black;
  font-size: small;
}

.ordered-items-card .products-card .qty {
  font-size: small;
}

.ordered-items-card .products-card .price {
  font-size: small;
}
`, "",{"version":3,"sources":["webpack://./src/pages/UserProfile/Orders/Orders.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,wBAAwB;EACxB,mBAAmB;EACnB,2CAA2C;AAC7C;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,wBAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".orders-container {\n  padding: 30px;\n}\n\n.ordered-items-card {\n  display: flex;\n  flex-flow: column nowrap;\n  margin-bottom: 30px;\n  border-bottom: 1px solid rgb(231, 231, 231);\n}\n.ordered-items-card > * {\n  margin-bottom: 6px;\n}\n\n.ordered-items-card .order-id-container {\n  font-weight: 600;\n}\n\n.ordered-items-card .payment-id-container {\n  font-size: small;\n}\n.payment-id-container {\n  color: grey;\n}\n\n.orders-container .price-container {\n  color: grey;\n  font-size: small;\n}\n\n.orders-container .products-card {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: grey;\n}\n\n.ordered-items-card img {\n  width: 90px;\n}\n\n.ordered-items-card .products-card .description {\n  display: flex;\n  flex-flow: column nowrap;\n  align-items: center;\n}\n\n.ordered-items-card .products-card .description > * {\n  margin-bottom: 5px;\n}\n.ordered-items-card .products-card .name {\n  color: black;\n  font-size: small;\n}\n\n.ordered-items-card .products-card .qty {\n  font-size: small;\n}\n\n.ordered-items-card .products-card .price {\n  font-size: small;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
