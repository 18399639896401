// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-profile-container {
  width: 90vw;
  margin: auto;
  margin-top: 100px;
  box-shadow: 0 0 23px 0 rgb(222, 222, 222);
  border-radius: 10px;
}

.user-profile-heading {
  text-align: center;
  margin-bottom: 15px;
}

.link-container {
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  padding: 15px;
  background-color: rgb(236, 236, 236);
}

.link-container > a {
  color: black;
  text-decoration: none;
  font-weight: 600;
}

@media (min-width: 768px) {
  .user-profile-container {
    width: 400px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/UserProfile/UserProfile.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,yCAAyC;EACzC,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,4BAA4B;EAC5B,aAAa;EACb,qBAAqB;EACrB,6BAA6B;EAC7B,aAAa;EACb,oCAAoC;AACtC;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".user-profile-container {\n  width: 90vw;\n  margin: auto;\n  margin-top: 100px;\n  box-shadow: 0 0 23px 0 rgb(222, 222, 222);\n  border-radius: 10px;\n}\n\n.user-profile-heading {\n  text-align: center;\n  margin-bottom: 15px;\n}\n\n.link-container {\n  border-radius: 10px 10px 0 0;\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: space-around;\n  padding: 15px;\n  background-color: rgb(236, 236, 236);\n}\n\n.link-container > a {\n  color: black;\n  text-decoration: none;\n  font-weight: 600;\n}\n\n@media (min-width: 768px) {\n  .user-profile-container {\n    width: 400px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
