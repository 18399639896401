// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout-container {
  text-align: center;
}

.logout-container button {
  color: white;
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
  background-color: var(--primary-color);
  font-weight: 600;
  margin: 15px 0 0px 0;
}

.logout-container button:hover {
  cursor: pointer;
  background-color: black;
}
`, "",{"version":3,"sources":["webpack://./src/pages/auth/Logout/Logout.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,sCAAsC;EACtC,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,uBAAuB;AACzB","sourcesContent":[".logout-container {\n  text-align: center;\n}\n\n.logout-container button {\n  color: white;\n  border: none;\n  padding: 10px 30px;\n  border-radius: 5px;\n  background-color: var(--primary-color);\n  font-weight: 600;\n  margin: 15px 0 0px 0;\n}\n\n.logout-container button:hover {\n  cursor: pointer;\n  background-color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
