import "./SizeModal.css";
import React from "react";


export const SizeModal = ({product, handleAddToCart, setIsModalOpen}) => {

    const handleSizeChange = (size_value) => {
        product = {
            ...product,
            size: size_value,
        };
        handleAddToCart(product);
        setIsModalOpen(false);
    }

    const closeSizeModal = () => {
        setIsModalOpen(false);
    }

    const sizeButtons = () => product.sizes.map(({size}) => <button
        onClick={() => handleSizeChange(size)}
        className="size-btn"
    >
        {size}
    </button>)

    return (
        <div className="size2-modal-container">
            <div className="size2-input-container">
                <button className="close-btn" onClick={closeSizeModal}>X</button>
                <h4>Choose your size</h4>
                <div className="size2-buttons-container">
                    {sizeButtons().map((e) => e)}
                </div>
            </div>
        </div>
    );
};