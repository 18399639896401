import "./AdminProductSection.css";
import React from "react";
import {AiFillMinusCircle, AiFillPlusCircle, AiOutlineEdit} from "react-icons/ai";
import Moment from "moment/moment";
import {RiAddFill} from "react-icons/ri";
import {ProductModal} from "./components/ProductModal/ProductModal";
import {useProducts} from "../../../../contexts/ProductsProvider";


export const AdminProductSection = () => {
    const {
        setProductForm,
        isProductModalOpen,
        setIsProductModalOpen,
        setIsEdit,
        productsDataState,
        getAdminCategoriesHandler
    } = useProducts();

    const addProductHandler = () => {
        getAdminCategoriesHandler();
        setIsProductModalOpen(true);
    };

    const editProductHandler = (product) => {
        getAdminCategoriesHandler();
        setProductForm(product);
        setIsProductModalOpen(true);
        setIsEdit(true);
    };

    return (
        <div>
            <button className="create-btn" onClick={addProductHandler}>
                <RiAddFill className="plus"/>
                New Product
            </button>
            <div className="admin-menu-products-container">
                {productsDataState.products.map((product) => (
                        <div className="admin-menu-product-card" key={product.id}>
                            <div>
                                <img
                                    className="admin-menu-product-img"
                                    alt={product.name}
                                    src={`/api/uploaded/${product.img}`}
                                />
                            </div>
                            <div className="product-description">
                                <h3>{product.name}</h3>
                                <p>Price:${product.original_price}</p>
                                <p>In stock: {
                                    product.is_stock ?
                                        <AiFillPlusCircle size={15} style={{color: "green"}}/>
                                        :
                                        <AiFillMinusCircle style={{color: "grey"}} size={15}/>}
                                </p>
                                <p>{Moment(product.date_created).format('YYYY-MM-DD')}</p>
                            </div>
                            <div className="button-section">
                                <div className="secondary-btn-section">
                                    <AiOutlineEdit
                                        size={25}
                                        onClick={() => editProductHandler(product)}
                                    />
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            {isProductModalOpen && <ProductModal/>}
        </div>
    );
};
