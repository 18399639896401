import "./App.css";
import {Header} from "./components/Header/Header";
import {Toaster} from "react-hot-toast";
import {NavRoutes} from "./routes/NavRoutes";
import {useData} from "./contexts/DataProvider";
import {Loader} from "./components/Loader/Loader";
import CookieConsent from "react-cookie-consent";
import React from "react";

function App() {
    const {loading} = useData();

    return (
        <div className="App">
            <div className="naw-label">** Worldwide Shipping **</div>
            <Header/>
            {loading && <Loader/>}
            <NavRoutes/>
            <Toaster
                position="top-right"
                reverseOrder={false}
                toastOptions={{
                    success: {duration: 1500},
                    error: {duration: 5000},
                }}
                containerStyle={{
                    top: "6rem",
                }}
            />
            <CookieConsent
                style={{
                    textAlign: "right",
                    background: "#fff",
                    color: "#000000",
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                }}
                buttonStyle={{ fontWeight: "bold", borderRadius: "3px", padding: "10px 20px" }}
                buttonText="I agree"
                // debug={true}
                cookieName="Cookies_Accepted"
            >
                This website collects cookies to deliver better user experience. By using this website you agree to our  <a style={{ color: "#000000", fontWeight: "bold" }} href="policies/terms-of-service">Terms of Service</a>.
            </CookieConsent>
        </div>
    );
}

export default App;