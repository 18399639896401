import {useState} from "react";
import React from "react";
import "./Filter.css";
import {useData} from "../../../../contexts/DataProvider";

export const Filter = () => {
    const {dispatch, state} = useData();
    const [categorySelected, setCategorySelected] = useState("");
    const [sortSelected, setSortSelected] = useState("");

    const handleCategoryChange = (e) => {
        e.preventDefault()
        setCategorySelected(e.target.value)
        dispatch({type: "ADD_CATEGORIES", payload: e.target.value})
    }

    const handleSortChange = (e) => {
        e.preventDefault()
        setSortSelected(e.target.value)
        dispatch({type: "ADD_SORT", payload: e.target.value})
    }

    return (
        <div className="filter-types-container">
            <div className="category-container">
                <p>Categories :</p>
                <select
                    className="category-select-container"
                    value={categorySelected}
                    onChange={handleCategoryChange}>
                    <option value={null}>All</option>
                    {state.allCategories?.map((option) => (
                        <option value={option.categoryName}>{option.categoryName}</option>
                    ))}
                </select>
            </div>
            <div className="category-container">
                <p>Sort by :</p>
                <select
                    className="category-select-container"
                    value={sortSelected}
                    onChange={handleSortChange}>
                    <option value={null}>All</option>
                    <option value={"highToLow"}>Price-high to low</option>
                    <option value={"lowToHigh"}>Price-low to high</option>
                    <option value={"newest"}>Newest</option>
                    <option value={"oldest"}>Oldest</option>
                </select>
            </div>
        </div>
    );
};
