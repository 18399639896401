import "./ProductRecommendationSection.css";
import React from "react";

import {BsFillStarFill} from "react-icons/bs";
import {useData} from "../../../../contexts/DataProvider";
import {Link} from "react-router-dom";

export const ProductRecommendationSection = () => {
    const {state} = useData();
    const {allProductsFromApi} = state;
    const stars = new Array(5).fill(null);

    return (
        <div className="product-card-container">
            {!allProductsFromApi.length ? (
                <h2 className="no-products-found">
                    Sorry, there are no products!
                </h2>
            ) : (
                allProductsFromApi.slice(0,3).map((product) => {
                    const {
                        id,
                        name,
                        img,
                        original_price,
                    } = product;

                    return (
                        <div className="product-card" key={id}>
                        <Link to={`/product-details/${id}`}>
                            <div className="product-card-image">
                                <img alt={name} src={`/api/uploaded/${img}`}/>
                            </div>
                        </Link>
                        <div className="product-card-details">
                            <div className="trending-name">
                                <h3>{name}</h3>
                            </div>

                            <div className="ratings-reviews">
                                <span></span>
                                {stars.map((_, index) => (
                                    <BsFillStarFill color={"orange"} key={index} />
                                ))}
                                
                            </div>
                            <div className="price-container">
                                <p className="discount-price">from € {original_price}</p>
                            </div>

                        </div>
                    </div>
                    );
                })
            )}
        </div>
    );
};
