import React, {useState} from "react";
import {useUserData} from "../../../../contexts/UserDataProvider";
import "./CartAmountSummary.css";
import {getPaymentPage} from "../../../../services/payment-services/addPaymentService";
import {CountryModal} from "./components/CountryModal/CountryModal";

export const CartAmountSummary = () => {
    const {userDataState, dispatch} = useUserData();

    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [isCountryOpen, setIsCountryOpen] = React.useState(false);
    const [selectedCountry, setSelectedCountry] = React.useState({
        value: userDataState.locationDetails.country_code,
        label: userDataState.locationDetails.country
    });


    const getCurrentCountryPrice = () => {
        let foundObject = userDataState.countries.find((obj) => obj.code === userDataState.locationDetails.country_code);
        return foundObject?.price || 0;
    }

    const getShippingTax = () => {
        let foundObject = userDataState.countries.find((obj) => obj.code === userDataState.locationDetails.country_code);
        return foundObject?.tax || null;
    }

    const calculateTotalDiscountedPrice = () => {
        let total = 0;

        userDataState.cartProducts?.map(({qty, discounted_price, original_price}) => {
            if (discounted_price && discounted_price !== 0) {
                total += discounted_price * qty;
                return total.toFixed(2);
            } else {
                total += original_price * qty;
                return total.toFixed(2);
            }
        });
        return total.toFixed(2);

    }

    const totalDiscountedPrice = calculateTotalDiscountedPrice();
    const totalShippingPrice = getCurrentCountryPrice();
    const totalShippingTax = getShippingTax();


    const calculateTotalPrice = () => {
        let total = parseFloat(totalDiscountedPrice) + parseFloat(totalShippingPrice);
        return total.toFixed(2);
    }

    const totalOriginalPrice = userDataState.cartProducts?.reduce(
        (acc, curr) => acc + curr.original_price * curr.qty,
        0
    );

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
    }

    const updateCountry = () => {
        userDataState.locationDetails.country_code = selectedCountry.value;
        userDataState.locationDetails.country = selectedCountry.label;
        setIsCountryOpen(false);
    };

    const placeOrderHandler = async () => {
        setIsLoading(true);
        setError("");
        dispatch({
            type: "SET_ORDER",
            payload: {
                cartItemsTotal: totalOriginalPrice,
                cartItemsDiscountTotal: totalDiscountedPrice,
            },
        });

        try {
            const response = await getPaymentPage(
                userDataState.cartProducts,
                userDataState.locationDetails.country_code
            );
            setIsLoading(false);
            window.location.replace(response.data.url);
        } catch (error) {
            console.error("Error", error)
            setError(error.response.data.detail.error)
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className="cart-price-container">
            <h1>Summary</h1>
            <div className="subtotal-container">
                <span>Sub-total: </span>
                <span>€{totalOriginalPrice}</span>
            </div>
            <div className="discount-container">
                <span>Discount: </span>
                <span>- € {totalOriginalPrice - totalDiscountedPrice}</span>
            </div>
            <div className="discount-container">
                <div style={{maxWidth: "80%"}}>
                    <span style={{overflowWrap: "break-word", width: "100%"}}>
                    {`Shipping to ${userDataState.locationDetails.country}`}
                    </span>
                    <br/>
                    <span style={{overflowWrap: "break-word", width: "100%"}}>
                        {`(${totalShippingTax ? "taxes and custom clearance NOT included" : "taxes and custom clearance already included"}):`}
                    </span>
                </div>

                <span>€ {getCurrentCountryPrice()}</span>
            </div>
            <div className="discount-container">
                <span onClick={() => setIsCountryOpen(!isCountryOpen)} style={{cursor: "pointer", color: "orange"}}>change country</span>
            </div>
            <div className="total">
                <span className="total-container">Total: </span>
                <span>€ {calculateTotalPrice()}</span>
            </div>

            <div className="total-discount-container">
                    <span> You saved €
                        {(totalOriginalPrice - totalDiscountedPrice).toFixed(2)}{" "}
                    </span>
            </div>
            {error && <span className="cart-error">{error}</span>}
            <button
                onClick={placeOrderHandler}
                className={`place-order-btn ${isLoading ? "loading" : ''}`}>
                {!isLoading ? 'Checkout' : <span className="spinner"></span>}
            </button>
            {isCountryOpen && <CountryModal
                countryName={selectedCountry}
                handleCountryChange={handleCountryChange}
                setIsCountryOpen={setIsCountryOpen}
                countries={userDataState.countries}
                updateCountry={updateCountry}
            />}
        </div>

    );
};
