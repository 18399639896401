import axios from "axios";

export const getAdminClientsList = async (access_token, token_type) => {
    return await axios.get("/api/admin/clients", {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `${token_type} ${access_token}`,
            },
        },
    );
};
