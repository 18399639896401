import {
    createContext,
    useContext,
    useEffect,
    useReducer, useRef,
    useState,
} from "react";
import {useAuth} from "./AuthProvider";
import {addToWishlistService} from "../services/wishlist-services/addToWishlistService";
import toast from "react-hot-toast";
import {useLocation, useNavigate} from "react-router-dom";
import {userDataReducer, initialUserData} from "../reducer/userDataReducer";
import {getProductDetails} from "../services/services";
import {getDeliveryCountriesService, getLocationService} from "../services/address-services/getLocationService";

const UserDataContext = createContext();

export function UserProvider({children}) {
    const [cartLoading, setCartLoading] = useState(false);
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    const [userDataState, dispatch] = useReducer(userDataReducer, initialUserData);

    const {auth, setAuth} = useAuth();

    const navigate = useNavigate();
    const location = useLocation();


    const handleLogout = () => {
        localStorage.removeItem("isAuth");
        localStorage.removeItem("access_token");
        localStorage.removeItem("token_type");
        localStorage.removeItem("user_id");
        setAuth({token: "", isAuth: false});
        dispatch({type: "SET_CART", payload: []});
        dispatch({type: "SET_WISHLIST", payload: []});
    };

    const getDeliveryCountries = async () => {
        try {
            const response = await getDeliveryCountriesService();
            dispatch({type: "SET_DELIVERY", payload: [...response.data.countries]});
        } catch (error) {
            console.error("error : ", error);
        }
    }

    const getUserLocation = async () => {
        const puppyPopLocation = JSON.parse(localStorage.getItem("puppyPopLocation"));

        if (!puppyPopLocation) {
            try {
                const response = await getLocationService();
                let data = {
                        country: response.data.country_name,
                        state: response.data.state,
                        city: response.data.city,
                        country_code: response.data.country_code,
                        postal: response.data.postal
                    }
                dispatch({
                    type: "SET_LOCATION", payload: data
                });
                localStorage.setItem("puppyPopLocation", JSON.stringify(data));
            } catch (error) {
                console.log("error : ", error);
            }
        } else {
            dispatch({
                type: "SET_LOCATION", payload: {
                    country: puppyPopLocation.country,
                    state: puppyPopLocation.state,
                    city: puppyPopLocation.city,
                    country_code: puppyPopLocation.country_code,
                    postal: puppyPopLocation.postal
                }
            });
        }
    };


    const addToCartHandler = async (product) => {
        if (!product.is_stock) {
            toast.error(`Sorry, ${product.name} is not in stock.`);
        } else {
            if (!isProductInCart(product)) {
                try {
                    setCartLoading(true);
                    toast.success(`${product.name} added to cart successfully!`);
                    product.qty = 1;
                    if (!product.size) {
                        product.size = product.sizes[0].size;
                    }
                    dispatch({type: "ADD_CART_ITEM", payload: product});
                    localStorage.setItem("puppyPopCart", JSON.stringify([...userDataState.cartProducts, product]));
                } catch (error) {
                    toast.error(error);
                } finally {
                    setCartLoading(false);
                }
            } else {
                navigate("/cart", {state: {from: location}});
            }
        }
    };

    const addToWishlistHandler = async (product) => {
        if (auth.isAuth) {
            try {
                const response = await addToWishlistService(product, auth.token, auth.token_type);
                dispatch({type: "SET_WISHLIST", payload: response.data.wishlist});
            } catch (error) {
                if (error.response?.status === 401) {
                    handleLogout();
                    toast.error("Your session expired. Please login again!");
                    navigate("/login", {state: {from: location}});
                } else {
                    dispatch({type: "SET_WISHLIST", payload: []});
                    toast.error(error.response?.data?.detail?.error);
                }
            }
        } else {
            handleLogout();
            toast.error("Please login first!");
            navigate("/login", {state: {from: location}});
        }
    };

    const getCartProducts = async () => {
        try {
            setCartLoading(true);
            const puppyPopCart = JSON.parse(localStorage.getItem("puppyPopCart"));
            if (puppyPopCart?.length > 0) {
                dispatch({type: "SET_CART", payload: puppyPopCart})
            } else {
                dispatch({type: "SET_CART", payload: []});
                localStorage.setItem("puppyPopCart", JSON.stringify([]));
            }
        } catch (error) {
            dispatch({type: "SET_CART", payload: []});
            localStorage.setItem("puppyPopCart", JSON.stringify([]));
            console.error(error);
        } finally {
            setCartLoading(false);
        }
    };

    const removeFromCartHandler = async (product) => {
        try {
            setCartLoading(true);
            const updatedCart = userDataState.cartProducts.filter((item) => !(item.id === product.id && item.size === product.size));
            dispatch({type: "SET_CART", payload: updatedCart});
            localStorage.setItem("puppyPopCart", JSON.stringify(updatedCart));
            toast.success(`${product.name} successfully removed from the cart `);
        } catch (error) {
            console.error(error);
        } finally {
            setCartLoading(false);
        }
    };

    const cartCountHandler = async (product, type) => {
        try {
            setCartLoading(true);
            if (type === "decrement" && product.qty === 1) {
                const updatedCart = userDataState.cartProducts.filter((item) => !(item.id === product.id && item.size === product.size));
                dispatch({type: "SET_CART", payload: updatedCart});
                localStorage.setItem("puppyPopCart", JSON.stringify(updatedCart));
                toast.success(`${product.name} successfully removed from the cart`);
            } else {
                const product_index = userDataState.cartProducts.findIndex(item => (item.id === product.id && item.size === product.size));
                if (product_index !== -1) {
                    if (type === "decrement") {
                        userDataState.cartProducts[product_index].qty = userDataState.cartProducts[product_index].qty - 1;
                        toast.success(`Removed one ${product.name} from the cart!`);
                    } else {
                        userDataState.cartProducts[product_index].qty = userDataState.cartProducts[product_index].qty + 1;
                        toast.success(`Added another ${product.name} to the cart!`);
                    }
                    dispatch({type: "SET_CART", payload: userDataState.cartProducts});
                    localStorage.setItem("puppyPopCart", JSON.stringify(userDataState.cartProducts));
                } else {
                    toast.error(`${product.name} not found in the cart!`);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setCartLoading(false);
        }
    };

    const wishlistHandler = async (product) => {
        setCartLoading(true);
        try {
            if (!isProductInWishlist(product)) {
                dispatch({type: "ADD_WISHLIST_ITEM", payload: product});
                toast.success(`${product.name} added to the wishlist successfully!`);
                localStorage.setItem("puppyPopWishlist", JSON.stringify([...userDataState.wishlistProducts, product]));
            } else {
                const updatedWishlist = userDataState.wishlistProducts.filter((item) => item.id !== product.id);
                dispatch({type: "SET_WISHLIST", payload: updatedWishlist});
                localStorage.setItem("puppyPopWishlist", JSON.stringify(updatedWishlist));
                toast.success(`${product.name} removed from the wishlist successfully!`);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setCartLoading(false);
        }
    };

    const getWishlistProducts = async () => {
        try {
            const puppyPopWishlist = JSON.parse(localStorage.getItem("puppyPopWishlist"));
            if (puppyPopWishlist?.length > 0) {
                dispatch({type: "SET_WISHLIST", payload: puppyPopWishlist})
            } else {
                dispatch({type: "SET_WISHLIST", payload: []});
                localStorage.setItem("puppyPopWishlist", JSON.stringify([]));
            }
        } catch (error) {
            dispatch({type: "SET_WISHLIST", payload: []});
            console.error(error);
        }
    };

    const removeFromWishlistHandler = async (product) => {
        setCartLoading(true);
        try {
            const updatedWishlist = userDataState.wishlistProducts.filter((item) => !(item.id === product.id));
            dispatch({type: "SET_WISHLIST", payload: updatedWishlist});
            localStorage.setItem("puppyPopWishlist", JSON.stringify(updatedWishlist));
            toast.success(`${product.name} removed from the wishlist successfully!`);
        } catch (error) {
            console.error(error);
        } finally {
            setCartLoading(false);
        }
    };

    const isProductInCart = (product) => {
        const found = userDataState.cartProducts.find(
            (item) => (item.id === product.id && item.size === product.size)
        );
        return !!found;
    };

    const isProductInWishlist = (product) => {
        const found = userDataState.wishlistProducts.find(
            (item) => item.id === product.id
        );
        return !!found;
    };

    const totalDiscountedPrice = userDataState.cartProducts?.reduce(
        (acc, curr) => acc + curr.discounted_price * curr.qty,
        0
    );

    const totalOriginalPrice = userDataState.cartProducts?.reduce(
        (acc, curr) => acc + curr.original_price * curr.qty,
        0
    );

    const discountPercent = () => {
        const totalPrice = userDataState?.cartProducts?.reduce(
            (acc, curr) => ({
                ...acc,
                original: acc.original + curr.original_price,
                discount: acc.discount + curr.discounted_price,
            }),
            {original: 0, discount: 0}
        );

        const totalDiscount =
            (totalPrice.original - totalPrice.discount) / totalPrice.original;

        return totalDiscount?.toFixed(2) * 100;
    };

    const clearCartHandler = async () => {
        try {
            dispatch({type: "SET_CART", payload: []});
            localStorage.setItem("puppyPopCart", JSON.stringify([]));
        } catch (error) {
            toast.error("Clear cart failed!");
            console.error(error);
        }
    };

    const getProductDetailsHandler = async (product_id) => {
        try {
            setCartLoading(true);
            const response = await getProductDetails(product_id);
            dispatch({type: "GET_PRODUCT_DETAILS", payload: [...response.data]});
        } catch (error) {
            console.error(error.response)
        } finally {
            setCartLoading(false);
        }
    };

    const openPaymentModal = (e) => {
        setIsPaymentModalOpen(e)
    };

    const refPage = useRef(null);

    useEffect(() => {
        getWishlistProducts();
        getCartProducts();
        getDeliveryCountries();
        getUserLocation();
    }, [auth]);

    return (
        <UserDataContext.Provider
            value={{
                userDataState,
                dispatch,
                addToCartHandler,
                addToWishlistHandler,
                removeFromWishlistHandler,
                isProductInCart,
                removeFromCartHandler,
                isProductInWishlist,
                totalDiscountedPrice,
                totalOriginalPrice,
                discountPercent,
                initialUserData,
                wishlistHandler,
                cartCountHandler,
                cartLoading,
                clearCartHandler,
                getProductDetailsHandler,
                isPaymentModalOpen,
                setIsPaymentModalOpen,
                openPaymentModal,
                getUserLocation,
                refPage
            }}
        >
            {children}
        </UserDataContext.Provider>
    );
}

export const useUserData = () => useContext(UserDataContext);
