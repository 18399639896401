import React from "react";
import {useData} from "../../contexts/DataProvider";

import "./Contact.css";
import {ContactForm} from "./components/ContactForm/ContactForm";
import {Link} from "react-router-dom";
import {Footer} from "../../components/Footer/Footer";

export const Contact = () => {
    const {loading} = useData();

    return (
        !loading && (
            <div>
                <div className="user-profile-container">
                    <div className="link-container">
                        <Link style={{color: "black"}}>Contact</Link>
                    </div>
                    <ContactForm/>
                </div>
                <Footer/>
            </div>
        )
    );
};
